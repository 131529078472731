import React, { Suspense, lazy, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Editar from "./componentes/abm/editar/editar.component";
import Listar from "./componentes/abm/listar/listar.component";
import ListConfigOptions from "./componentes/configuracion/list-config-options/list-config-options";
import Login from "./pages/login/login.component";
import Spinner from "./componentes/spinner/with-spinner.component";
import { useContext } from "react";
import { UserContext } from "./provider/user/user.provider";
import NuevoDashboardContainer from "./pages/nuevoDashboard/dashboard.container";
import ObjetivosContainer from "./componentes/objetivos/objetivo-container.component";
import EditObjetivo from "./componentes/objetivos/edit-objetivo.component";

const HomeBO = lazy(() => import("./pages/home-bo/home-bo.page"));

const EditConfig = lazy(() =>
	import("./componentes/configuracion/edit-config/edit-config.component")
);
const ShowConfig = lazy(() =>
	import("./componentes/configuracion/show-config/show-config.component")
);
const Ingreso = lazy(() => import("./pages/ingreso/ingresoFlow"));
const Asignacion = lazy(() => import("./pages/asignacion/asignacionFlow"));
const Home = lazy(() => import("./pages/home/home"));
const DashboardContainer = lazy(() =>
	import("./pages/charts/dashboard.container")
);
//Hay un tema aca que generar un monton de warnings, no se bien porque.
//https://stackoverflow.com/questions/66463284/react-router-warning-route-elements-should-not-change-from-controlled-to-unco
// es lo unico que encontre
const Routes = () => {
	const ABMs = ["CanalContacto", "CanalIngreso", "MotivoInconducente"];
	const { user, userLoggedIn } = useContext(UserContext);
	return (
		<Fragment>
			<Suspense fallback={<Spinner isLoading={true} />}>
				<Switch>
					<Route exact path="/Login" component={Login} />
					{!userLoggedIn() ? <Redirect to="/Login" /> : null}
					<Route exact path="/Ingreso" component={Ingreso} />
					<Route exact path="/Asignacion" component={Asignacion} />
					<Route exact path="/HomeBO" component={HomeBO} />
					<Route exact path={["/Home", "/"]} component={Home} />
					{user.userSuperUsuario ? (
						<>
							<Route exact path="/Dashboard" component={DashboardContainer} />
							<Route
								exact
								path="/DashboardNuevo"
								component={NuevoDashboardContainer}
							/>
							<Route exact path="/Config/" component={ListConfigOptions} />
							<Route
								exact
								path="/Config/Objetivos"
								component={ObjetivosContainer}
							/>
							<Route
								exact
								path="/Config/Objetivos/:id"
								component={EditObjetivo}
							/>
							<Route exact path="/Config/Variables" component={ShowConfig} />
							<Route
								exact
								path="/Config/Variables/:id"
								component={EditConfig}
							/>
							{ABMs.map((abm, ix) => [
								<Route exact path={`/Config/${abm}/`} key={ix}>
									<Listar modelo={abm} />
								</Route>,
								<Route
									key={`${ix}_edit`}
									exact
									path={`/Config/${abm}/:id`}
									render={(routeProps) => (
										<Editar modelo={abm} id={routeProps.match.params.id} />
									)}
								/>,
							])}
						</>
					) : null}
				</Switch>
			</Suspense>
		</Fragment>
	);
};
export default Routes;
