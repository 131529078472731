import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

const PieChartComponent = ({
    data,
    dataKey,
    labelKey,
    colores,
    startAngle = 0,
    endAngle = 0,
    innerRadius = 0,
    outerRadius = 80,
    legendValue = null,
    aspect = 3,
    showLabel = false,
}) => {
    const displayLabel = () => {
        if (!showLabel) return null;
        return (entry) => entry[labelKey];
    };
    return (
        <ResponsiveContainer width='100%' height='auto' aspect={aspect}>
            <PieChart>
                {legendValue ? (
                    <Legend
                        verticalAlign='middle'
                        align='center'
                        payload={[
                            {
                                value: legendValue,
                                type: "line",
                                id: "ID01",
                            },
                        ]}
                    />
                ) : null}
                <Pie
                    cy={"50%"}
                    cx={"50%"}
                    dataKey={dataKey}
                    data={data}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    outerRadius={`${outerRadius}%`}
                    innerRadius={`${innerRadius}%`}
                    label={displayLabel()}>
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colores[index % colores.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieChartComponent;
