import { BrowserRouter, NavLink } from "react-router-dom";
import Routes from "./routes";
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import MetaTags from "react-meta-tags";
import { useContext } from "react";
import { UserContext } from "./provider/user/user.provider";

function App() {
    const { user } = useContext(UserContext);
    document.title = "Andrea Falcone App.";
    return (
        <Container fluid>
            <MetaTags>
                <title>{document.title}</title>
                <meta
                    name='description'
                    content='Complemento del Backoffice.'
                />
                <meta property='og:title' content={document.title} />
            </MetaTags>
            <BrowserRouter>
                <nav aria-label='breadcrumb' style={{ display: "none" }}>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <NavLink
                                activeClassName='activeLink'
                                exact
                                to='/Home'>
                                Prospectos
                            </NavLink>
                        </li>
                        <li className='breadcrumb-item'>
                            <NavLink
                                activeClassName='activeLink'
                                exact
                                to='/Ingreso'>
                                Ingresar
                            </NavLink>
                        </li>
                        {user.userSuperUsuario ? (
                            <>
                                <li className='breadcrumb-item'>
                                    <NavLink
                                        activeClassName='activeLink'
                                        exact
                                        to='/Config'>
                                        Configuración
                                    </NavLink>
                                </li>
                                <li className='breadcrumb-item'>
                                    <NavLink
                                        activeClassName='activeLink'
                                        exact
                                        to='/Dashboard'>
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className='breadcrumb-item'>
                                    <NavLink
                                        activeClassName='activeLink'
                                        exact
                                        to='/DashboardNuevo'>
                                        Dashboard Nuevo
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                    </ol>
                </nav>
                <Routes />
            </BrowserRouter>
            <ToastContainer />
        </Container>
    );
}

export default App;
