import React from "react";
import { Form } from "react-bootstrap";

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    // Calculate the options for filtering using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    // Render a multi-select box
    return (
        <Form.Control
            as='select'
            size='sm'
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            // style={{ height: "auto" }}
        >
            <option value=''>Todos</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Form.Control>
    );
};
export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <Form.Control
            size='sm'
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            onClick={(e) => e.stopPropagation()} //para que no reordene la columna
            placeholder='Ingrese 3 carácteres para buscar...'
        />
    );
};
export const prospectoFilter = (rows, id, filterValue) => {
    filterValue = filterValue.toLowerCase();
    if (filterValue && filterValue.length >= 3) {
        return rows.filter((row) => {
            const { prospecto } = row.values[id];
            return prospecto.toLowerCase().indexOf(filterValue) >= 0
                ? row
                : null;
        });
    }
    return rows; //con menos de 3 letras, no filtra.
};
