import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const ListConfigOptions = () => {
    const links = [
        { url: "/Config/Variables/", title: "Variables" },
        { url: "/Config/CanalContacto/", title: "Canal de Contacto" },
        { url: "/Config/CanalIngreso/", title: "Canal de Ingreso" },
        { url: "/Config/MotivoInconducente/", title: "Motivo Inconducente" },
        { url: "/Config/Objetivos/", title: "Objetivos" },
    ];
    return (
        <Container fluid>
            <h1>Configuración del Sistema de Prospectos</h1>
            <ul>
                {links.map((l) => (
                    <li key={l.title}>
                        <Link to={l.url}>{l.title}</Link>
                    </li>
                ))}
            </ul>
        </Container>
    );
};

export default ListConfigOptions;
