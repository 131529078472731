import { createContext, useState } from "react";

const MANDATORY_TRAMITES = new Map()
	.set(1, "Jubilación")
	.set(2, "Pensión por Fallecimiento")
	.set(3, "PUAM")
	.set(4, "Retiro por invalidez")
	.set(7, "Reajuste de haberes")
	.set(12, "Jubilación Ampliatoria");

export const azulEnGrilla = (strTramite, strEstado) => {
	if (!strTramite) return false;
	if (strEstado !== "Stand By") return false;
	const values = [...MANDATORY_TRAMITES.values()];
	return values.includes(strTramite);
};

export const RequiredContext = createContext({
	mandatory: false,
	updateMandatories: () => {},
});

export const RequiredContextProvider = ({ children }) => {
	const [mandatory, setMandatory] = useState(true);

	const updateMandatories = (idTramite) => {
		if (!idTramite || Number.isNaN(idTramite)) return;
		const id = Number.parseInt(idTramite);

		if (MANDATORY_TRAMITES.has(id)) setMandatory(false);
		else setMandatory(true);
	};

	return (
		<RequiredContext.Provider
			value={{
				mandatory,
				updateMandatories,
			}}
		>
			{children}
		</RequiredContext.Provider>
	);
};
