import BarChartComponent from "../componentes/barchart/barchart.component";
import PieChartComponent from "../componentes/piechart/piechart.component";
import chroma from "chroma-js";

export const TEXT_BTN_CONFIRM = {
    CERRAR_CONSULTA: "Cerrar Consulta",
    AGREGAR: "Agregar",
    EDITAR: "Editar",
};

export const TIPO_TRAMITE = {
    PUAM: "PUAM",
    REAJUSTE_MOVILIDAD: "Reajuste Movilidad",
    RECLAMO_GANANCIAS: "Reclamo de ganancias",
    INFORME_JUICIO: "Informe de juicio",
    REAJUSTE_HABERES: "Reajuste de haberes",
    JUICIO_BAPRO: "Juicio BAPRO",
    JUBILACION: "Jubilación",
    PENSION_FALLECIMIENTO: "Pensión por Fallecimiento",
    CONSULTA: "Consulta",
    RETIRO_POR_INVALIDEZ: "Retiro por invalidez",
    CALCULO: "Calculo de haber",
    JUBILACION_AMPLIATORIA:"Jubilación Ampliatoria",
    SEGURO_DE_RETIRO:"Seguro de Retiro",
    MORATORIA:"Moratoria 2023"
};
export const TIPO_CONSULTA = {
    JUBILACION: "Jubilación",
    PENSION_DIRECTA: "Pensión Directa",
    RETIRO_POR_INVALIDEZ: "Retiro por Invalidez",
};
export const TIPO_BENEFICIO = {
    PENSION_NO_CONTRIBUTIVA: "Pensión No Contributiva",
    PENSION_MADRE_7_HIJOS: "Pensión Madre 7 Hijos",
    PUAM: "PUAM",
    JUBILACION_PROVINCIAL_MUNICIPAL: "Jubilación Provincial/Municipal",
    RETIRO_FFAA_POLICIA: "Retiro FFAA/Policia",
    PENSION_POR_VIUDEZ: "Pensión por Viudez",
};
export const VARIABLES_CONFIG = {
    MINIMO_GANANCIAS: "Mínimo Ganancias",
    JUBILACION_INGRESOS_ULTIMO_AÑO: "Jubilacion Ingresos Ultimo Año",
    JUBILACION_GASTOS_TARJETA_ULTIMO_AÑO: "Jubilacion Tarjeta Ultimo Año",
};
export const ENCUADRE_OPCIONES = {
    PUEDE_CONTINUAR: "Puede continuar",
    NO_PUEDE_CONTINUAR: "No puede continuar",
};
export const ESTADO_TRAMITE = {
    BAJA: "Baja",
    ASIGNACION: "Asignación",
    ENCUADRE: "Encuadre",
    STANDBY: "Stand By",
    CIERRE: "Cierre",
};

export const MIN_VALUES = {
    MIN_INT: "0",
    MIN_DATE: "1700-01-01T00:00:00.000Z",
};
export const MENSAJES_BAJA = {
    NO_TIENE_APORTES:
        "Sin aportes no es posible jubilarse con las moratorias vigentes",
    JUBILACION_PROVINCIAL_MUNICIPAL:
        "La jubilación de ANSES no es compatible con una jubilación de este tipo",
    JUBILACION_NO_COMPATIBLE:
        "La jubilación no es compatible con este tipo de beneficios.",
    RETIRO_FFAA:
        "La jubilación es compatible en la medida en que los servicios civiles no hayan sido usados para lograr el haber de retiro. Asimismo, avisar que será necesaria una nota de la caja de las FFAA o Policial donde indiquen que las tareas desempeñadas eran compatibles con los servicios civiles prestados simultáneamente",
    EDAD_MINIMA: "Para jubilarse se necesitan al menos 30 años en el país",
    CONCUBINO_PRUEBA:
        "El concubino deberá probar convivencia al menos los últimos 2 años si tenían hijos en común, sino los últimos 5 años",
    SOLICITANTE_HIJO:
        "Para poder percibir la jubilación de los padres, el hijo mayor debe encontrarse incapacitado para trabajar: 66% de discapacidad. Será controlado por una comisión médica.",
    NO_SUPERA_EVALUACION:
        "No supera la evaluación socioeconómica, no podrá gestionar la PUAM",
    PUAM_INCOMPATIBLE:
        "La PUAM es incompatible con la percepción de cualquier otro beneficio",
    PUAM_20_AÑOS:
        "Para la PUAM se necesitan al menos 20 años en el país para el caso de extranjeros residentes y 10 para el caso de extranjeros nacionalizados argentinos",
    RTI_20_AÑOS:
        "Para Retiro por Invalidez se necesitan al menos 20 años en el país para el caso de extranjeros residentes y 10 para el caso de extranjeros nacionalizados argentinos.",
    RTI_SIN_APORTES:
        "Sin aportes en los últimos 5 años no tiene regularidad para un retiro por invalidez",
    RTI_INCOMPATIBLE:
        "El Retiro por Invalidez es incompatible con la percepción de cualquier otro beneficio. Elija Continuar si desea cambiar una pensión no contributiva por la jubilación.",
};
export const TIPO_CHART = {
    TORTA: PieChartComponent,
    BARRAS: BarChartComponent,
};
export const PROFESIONALES = [
    /*Sacada por AB-16 (Jira Issue) */
    /*{ nombre: "Andrea", id: 10 },*/
    { nombre: "Carla", id: 24 },
    { nombre: "Lucia", id: 40 },
    { nombre: "Carolina Boffa", id: 63 },
    { nombre: "Karina", id: 78 },
    { nombre: "Clarisa Lopez", id: 49 },
    { nombre: "Cecilia Renno", id: 60 },
    { nombre: "Gabriela Rosende", id: 103 },
];

export const MOTIVOSBAJA = [
    { descripcion: "Otros", id: 0 },
    { descripcion: "Honorarios", id: 1 },
    { descripcion: "Técnica", id: 2 },
    { descripcion: "Espera", id: 3 },
    { descripcion: "Perdimos Contacto", id: 4 },
];
export const MOTIVOSSTANDBY = [
    { descripcion: "Otros", id: 0 },
    { descripcion: "Honorarios", id: 1 },
    { descripcion: "Técnica", id: 2 },
    { descripcion: "Personal", id: 3 },
    { descripcion: "Pendiente documentación cliente", id: 4 },
    { descripcion: "Documentación DDC", id: 5 },
    { descripcion: "Ampliatoria", id: 6 },
    { descripcion: "Convenio", id: 7 },
    { descripcion: "Re-Asignación", id: 8 },
];
export const URL_BASE_ADJUNTOS =
    "http://backoffice.andreafalcone.com/documentos";
export const TEXTO_DESCARGA = "Click para ver/descargar el archivo";

export const MESES = [
    "", //IX = 0
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];

const colores_bo = ["#cab58a", "#b59d81", "#d2c9c2"];
const chromaColors = chroma.scale([...colores_bo]);

export const GetColorFalcone = (ix) => {
    return chromaColors(ix);
};
//Para traducir lo devuelto del BE por el label pedido por el user.
export const MapNombresTraduccion = [
    {
        periodo: "semana",
        nombre: "cantidadUltimosDias",
        traduccion: "Última Semana",
    },
    { periodo: "mes", nombre: "cantidadUltimosDias", traduccion: "Último mes" }, // TODO: Esto lo puse asi ahora porque en los issue aparece el ultimo mes, pero confirmar si es asi.
    { nombre: "cantidadAntiguos", traduccion: "Más Antiguos" },
    { nombre: "promedioDias", traduccion: "Antiguedad Promedio" },
    { nombre: "cantidadUltimoMes", traduccion: "Últimos Días" }, //hay que cambiar el nombre del obj que viene del BE
    { nombre: "cantidadTotal", traduccion: "Total" },
];
