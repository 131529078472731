import { MESES } from "../../scripts/consts";
import BarChartJS from "../bar-chartjs/bar-chartjs.component";
import { getColorsHex } from "../../scripts/chartScripts";

const VariacionInteranual = ({ variacionInteranual }) => {
    const { cantActual = 1, cantAnterior = 1 } =
        variacionInteranual.find(
            (vi) => vi.mes === new Date().getMonth() + 1
        ) || {};
    const diferencia = () => {
        const signo = cantActual > cantAnterior ? "+" : "-";
        return `${signo}${
            Math.round((cantActual * 100) / cantAnterior) - 100
        }%`;
    };
    const getDataSet = () => {
        const [color1, color2] = getColorsHex([1, 2]);
        const ret = [
            {
                label: new Date().getFullYear() - 1,
                data: variacionInteranual.map((vi) => vi.cantAnterior),
                backgroundColor: color1,
            },
            {
                label: new Date().getFullYear(),
                data: variacionInteranual.map((vi) => vi.cantActual),
                backgroundColor: color2,
            },
        ];
        return ret;
    };
    return (
        <div className='grdVariacionInteranual'>
            <span className='variacionInteranual'>{diferencia()}</span>
            <BarChartJS
                labels={variacionInteranual.map((vi) => MESES[vi.mes])}
                dataSets={getDataSet()}
                // title='Pendientes por Asesor'
            />

            {/* <LineChart
                // title='&nbsp;'
                // titlePosition='bottom'
                labels={variacionInteranual.map((vi) => MESES[vi.mes])}
                dataValues={[
                    {
                        label: new Date().getFullYear(),
                        values: variacionInteranual.map((vi) => vi.cantActual),
                    },
                    {
                        label: new Date().getFullYear() - 1,
                        values: variacionInteranual.map(
                            (vi) => vi.cantAnterior
                        ),
                    },
                ]}
                legend='Variación Interanual'
            /> */}
            <h4 className='subNumeroPrincipal'>Variación Interanual</h4>
        </div>
    );
};

export default VariacionInteranual;
