import { useContext, useEffect, useState } from "react";
import { ListadoChartMultiValueResuelto } from "../../../componentes/listadochart/ListadoChart-MultiValue-Resuelto.component";
import { ListadoChartMultiValue } from "../../../componentes/listadochart/ListadoChart-MultiValue.component";
import { SwiperContext } from "../../../provider/swiper/swiper.provider";
import { Get } from "../../../scripts/fetch";

// 121
// Pase el parametro del asesor por useContext con setActiveID
const DashboardPrincipalDesempeño = ({ swipe }) => {
    const { setActiveID } = useContext(SwiperContext);

    const [cierresPorOrigen, setcierresPorOrigen] = useState();
    const [efectividadVendedores, setefectividadVendedores] = useState();
    const [asesores, setAsesores] = useState();

    useEffect(() => {
        const getCierresPorOrigen = async () => {
            Get(`charts/consultasCierresPorOrigen`).then((d) => {
                setcierresPorOrigen(d.obj);
            });
        };

        const getEfectividadVendedores = async () => {
            Get(`charts/rankingEfectividadVendedores`).then((d) => {
                setAsesores(
                    d.obj.map((asesor) => ({
                        id: asesor.idAsesor,
                        nombre: asesor.asesor,
                    }))
                );
                const totalAsignados = d.obj.reduce(
                    (tot, current) => current.asignados + tot,
                    0
                );
                const totalCierres = d.obj.reduce(
                    (tot, current) => current.cierres + tot,
                    0
                );
                const dataFinal = d.obj.map((ef) => ({
                    ...ef,
                    asignados: Math.round(
                        (ef.asignados * 100) / totalAsignados
                    ),
                    cierres: Math.round((ef.cierres * 100) / totalCierres),
                }));
                setefectividadVendedores(dataFinal);
            });
        };

        // getRankingVendedores();
        getCierresPorOrigen();
        getEfectividadVendedores();
    }, []);

    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Desempeño del Equipo</h1>
            </div>
            <div className='mitad'>
                {cierresPorOrigen && (
                    <ListadoChartMultiValue
                        data={cierresPorOrigen.map((cp) => ({
                            name: cp.canalDeIngreso,
                            value: [cp.totalContactos, cp.totalCierres],
                        }))}
                        labelsValues={["Consultas", "Cierres"]}
                        progressBar={true}
                        title='Resultados Filtro'
                    />
                )}
                {efectividadVendedores && asesores && (
                    <ListadoChartMultiValueResuelto
                        data={efectividadVendedores.map((ef) => ({
                            name: ef.asesor,
                            value: [ef.asignados, ef.cierres, ef.efectividad],
                        }))}
                        labelsValues={["Asignados", "Cierres", "Efectividad"]}
                        onClickFn={(entry) => {
                            const { id } = asesores.find(
                                (a) => a.nombre === entry.name
                            );
                            setActiveID(id);
                            swipe(12);
                        }}
                        progressBar={true}
                        title='Resultados Cierre'
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardPrincipalDesempeño;
