import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EncuadreProfesionales from "../componentes/encuadre-profesional/encuadre-profesional.component";
import MotivosBaja from "../componentes/motivo-baja/motivo-baja.component";
import MotivosStandBy from "../componentes/motivo-standby/motivo-standby.component";

export const objVacio = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

export const getTextFromSelect = (e) =>
    e.target.options[e.target.selectedIndex].text;

export const calcularEdad = (fecha) => {
    // console.log(fecha);
    if (fecha) {
        let arrDate = fecha.split("-");
        if (arrDate.length === 3) {
            if (arrDate[0].length === 4) {
                if (arrDate[0] > 1900) return aniosAFecha(arrDate);
            }
        }
    }
};

export const diff_years = (dt2, dt1) => {
    var diff = (Date.parse(dt2).getTime() - Date.parse(dt1).getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
};

export const aniosAFecha = (arrDate, fecha = Date.now()) => {
    //TODO ver porque le agrega un mes mas cuando crea la fecha
    let d = new Date(arrDate[0], arrDate[1]-1, arrDate[2], 0, 0, 0, 0);
    let v = new Date(fecha - d.getTime()).getUTCFullYear() - 1970;
    return v < 0 ? 0 : v;
};

export const removeRepeatedProps = (obj1, obj2) => {
    for (let p2 in obj2) {
        if (obj1[p2]) delete obj1[p2];
    }
    return obj1;
};

export const getValue = (obj) => {
    if (obj) return obj;
    return "";
};
export const getChecked = (obj) => {
    if (obj) return obj;
    return false;
};

export const confirmar = (
    mensaje,
    resultado,
    botones,
    titulo = "Andrea Falcone App."
) => {
    let buttons = [
        {
            label: "Si",
            onClick: () => resultado(true),
        },
        {
            label: "No",
            onClick: () => resultado(false),
        },
    ];
    if (botones) {
        buttons = [];
        botones.forEach((b) => {
            buttons.push({
                label: b,
                onClick: () => resultado(b),
            });
        });
    }
    confirmAlert({
        title: titulo,
        message: mensaje,
        buttons: buttons,
    });
};
export const confirmarEncuadre = (profesionales, result) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <EncuadreProfesionales
                profesionales={profesionales}
                resultado={result}
                onClose={onClose}
            />
        ),
    });
};

export const confirmarMotivoBaja = (motivos, result) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <MotivosBaja
                motivos={motivos}
                resultado={result}
                onClose={onClose}
            />
        ),
    });
};
export const confirmarMotivoStandBy = (motivos, result) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <MotivosStandBy
                motivos={motivos}
                resultado={result}
                onClose={onClose}
            />
        ),
    });
};

export const domicilioCompleto = (domicilio) => {
    if (
        domicilio &&
        domicilio.calle &&
        domicilio.altura &&
        domicilio.unidad &&
        domicilio.cp &&
        domicilio.idprovincia &&
        domicilio.idlocalidad
    )
        return true;
    return false;
};
