import { Link } from "react-router-dom";
import {
	DefaultColumnFilter,
	prospectoFilter,
	SelectColumnFilter,
} from "../scripts/tableScripts";
import { MIN_VALUES } from "../scripts/consts";

/*
apellido: "Culen"
fechaAsignacion: "2021-01-19T00:00:00.000Z"
fechaCreacion: "2021-01-18T17:50:01.112Z"
idAsesor: 1
idUsuarioAsignacion: 1
nombre: "Fernando"
*/
const ObjHomeColumns = [
	{
		Header: "Fecha de alta",
		accessor: "fechaCreacion", // accessor is the "key" in the data
		Cell: ({ value }) => {
			return new Date(value).toLocaleDateString();
		},
	},
	{
		Header: "Fecha seguimiento",
		accessor: (Row) =>
			Row.fechaAsignacion !== MIN_VALUES.MIN_DATE ? Row.fechaAsignacion : "",

		Cell: ({ value }) => {
			return value && value !== MIN_VALUES.MIN_DATE
				? new Date(value).toLocaleDateString()
				: "";
		},
	},
	{
		Header: "Prospecto",
		accessor: "prospecto",
		Cell: ({ cell: { value } }) => (
			<Link
				to={{ pathname: `/Asignacion`, id: value._id }}
				key={value._id}
			>{`${value.prospecto}`}</Link>
		),
		Filter: DefaultColumnFilter,
		filter: prospectoFilter,
		disableFilters: false,
	},
	{
		Header: "Asesor",
		accessor: (Row) =>
			Row.idAsesor !== MIN_VALUES.MIN_INT ? Row.idAsesor : "",
		Filter: SelectColumnFilter,
		// filter: 'includes', //es un int, no le gusta el include sino el ===, si termina siendo un nombre, seguramente debamos ponerlo.
		disableFilters: false,
	},
	{
		Header: "Tutor",
		accessor: (Row) => (Row.nombreTutor ? Row.nombreTutor : ""),
		Filter: SelectColumnFilter,
	},
	{
		Header: "Tipo de Trámite",
		accessor: "tipoTramite",
		Filter: SelectColumnFilter,
		filter: "includes",
		disableFilters: false,
	},
	{
		Header: "Estado",
		accessor: "estado",
		Filter: SelectColumnFilter,
		filter: "includes",
		disableFilters: false,
	},
	{
		Header: "Motivo",
		accessor: "motivo",
	},
	{
		Header: "Prioritario",
		accessor: "prioritario",
	},
];
export const ObjetivosColumns = [
	{
		Header: "Tipo de Tramite",
		accessor: "TipoTramite", // accessor is the "key" in the data
	},
	{
		Header: "Objetivo",
		accessor: "Objetivo",
	},
	{
		Header: "",
		accessor: "id",
		Cell: ({ cell: { value } }) => (
			<div className="d-flex justify-content-end">
				<Link
					to={{
						pathname: `/Config/Objetivos/${value}`,
						id: value,
					}}
					className="btn btn-mini btn-success"
				>
					<i className="icon-edit icon-white" />
				</Link>
			</div>
		),
	},
];
export const ObjHomeBackOfficeColumns = [
	{
		Header: "Fecha de alta",
		accessor: "fechaCreacion", // accessor is the "key" in the data
		Cell: ({ value }) => {
			return new Date(value).toLocaleDateString();
		},
	},
	{
		Header: "Fecha seguimiento",
		accessor: (Row) =>
			Row.fechaAsignacion !== MIN_VALUES.MIN_DATE ? Row.fechaAsignacion : "",

		Cell: ({ value }) => {
			return value && value !== MIN_VALUES.MIN_DATE
				? new Date(value).toLocaleDateString()
				: "";
		},
	},
	{
		Header: "Prospecto",
		accessor: "prospecto",
		Cell: (cell) => {
			const { value } = cell;
			return (
				<span
					style={{ textDecoration: "underline", color: "#5b80b6" }}
					onClick={() => {
						if (window.parent) {
							window.parent.postMessage(
								{
									func: "parentFunc",
									message: { id: value._id },
								},
								"*"
							);
						}
					}}
				>
					{value.prospecto}
				</span>
			);
			// 	<Link
			// 		to={{ pathname: `/Asignacion`, id: value._id }}
			// 		key={value._id}
			// 	>{`${value.prospecto}`}</Link>
			// );
		},
		Filter: DefaultColumnFilter,
		filter: prospectoFilter,
		disableFilters: false,
	},
	{
		Header: "Asesor",
		accessor: (Row) =>
			Row.idAsesor !== MIN_VALUES.MIN_INT ? Row.idAsesor : "",
		Filter: SelectColumnFilter,
		// filter: 'includes', //es un int, no le gusta el include sino el ===, si termina siendo un nombre, seguramente debamos ponerlo.
		disableFilters: false,
	},
	{
		Header: "Tutor",
		accessor: (Row) => (Row.nombreTutor ? Row.nombreTutor : ""),
		Filter: SelectColumnFilter,
	},
	{
		Header: "Tipo de Trámite",
		accessor: "tipoTramite",
		Filter: SelectColumnFilter,
		filter: "includes",
		disableFilters: false,
	},
	{
		Header: "Estado",
		accessor: "estado",
		Filter: SelectColumnFilter,
		filter: "includes",
		disableFilters: false,
	},
	{
		Header: "Motivo",
		accessor: "motivo",
	},
	{
		Header: "Prioritario",
		accessor: "prioritario",
	},
];
export { ObjHomeColumns };
