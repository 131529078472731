import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

export const ListadoChartMultiValueResuelto = ({
    data = [],
    //
    onClickFn,
    progressBar = true,
    title = null,
    subtitle = null,
    labelsValues = [],
}) => {
    const ixPorcentajeProgressBar = data[0].value.length - 1; //Ultima columna es la que se muestra el % en el progress
    //Se crean los estilos de forma dinamica
    const estilo = {
        display: "grid",
        gridTemplateColumns: `2fr 2fr repeat(${data[0].value.length}, minmax(auto, 0.75fr)`,
        alignItems: "center",
    };
    const estiloLabels = {
        display: "grid",
        gridTemplateColumns: `2fr 2fr repeat(${labelsValues.length}, minmax(min-content, 0.75fr)`,
        justifyItems: "center",
        alignItems: "center",
        gap: "0.5rem",
        fontSize: "small",
    };

    return (
        <div className='card'>
            <div className='card-body'>
                {title && <h1 className='listadoTitle'>{title}</h1>}
                <div style={estiloLabels}>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    {labelsValues.map((lbl) => (
                        <span key={lbl}>{lbl}</span>
                    ))}
                </div>
                {data.map((entry) => (
                    <div
                        style={estilo}
                        className={`${onClickFn ? "clickable" : ""}`}
                        key={entry.name}
                        onClick={() => {
                            if (onClickFn) onClickFn(entry);
                        }}>
                        <span>{entry.name}</span>
                        {progressBar && (
                            <span>
                                <ProgressBar>
                                    <ProgressBar
                                        variant='success'
                                        now={
                                            entry.value[ixPorcentajeProgressBar]
                                        }
                                        key={1}
                                    />
                                    <ProgressBar
                                        variant='danger'
                                        now={
                                            100 -
                                            entry.value[ixPorcentajeProgressBar]
                                        }
                                        key={2}
                                    />
                                </ProgressBar>
                            </span>
                        )}
                        {entry.value.map((val, ix) => (
                            <span className='valueEnGrid' key={ix}>
                                {`${val}%`}
                            </span>
                        ))}
                    </div>
                ))}
                {subtitle && <div className='subtitle'>{subtitle}</div>}
            </div>
        </div>
    );
};
