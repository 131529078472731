import { useEffect, useState } from "react";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../../componentes/pie-chartjs/pie-chartjs.component";
import {
    createFormatableObject,
    exportToCSV,
    fetchListado,
    formatData,
    getIdTipoTramite,
} from "../../../scripts/chartScripts";
import { Get } from "../../../scripts/fetch";
import { MensajeError } from "../../../scripts/mensajePantalla";

const DashboardProspectosEncuadre = () => {
    const [encuadresUltimosDias, setencuadresUltimosDias] = useState();
    const [encuadresPorTipoTramite, setencuadresPorTipoTramite] = useState();
    useEffect(() => {
        const getEncuadresUltimosDias = async () => {
            Get(`charts/encuadresUltimosDias?dias=30`).then((d) => {
                setencuadresUltimosDias(createFormatableObject(d.obj));
            });
        };
        const getEncuadresPorTipoTramite = async () => {
            Get(`charts/encuadresPorTipoDeTramite?dias=30`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setencuadresPorTipoTramite(d.obj);
            });
        };
        getEncuadresUltimosDias();
        getEncuadresPorTipoTramite();
    }, []);
    const ExcelListadosAntiguedad = (entry) => {
        let url = "encuadreAnterioresUltimosDias";
        let fileName = "prospectos_encuadres_antiguos";
        if (entry.name === "Última Semana") {
            url = "encuadreCreadosUltimosDias";
            fileName = "prospectos_encuadres_semana";
        }
        exportToCSV(() => fetchListado(url), fileName);
    };
    const ExcelListadoTipoTramite = async (entry) => {
        const { name } = entry || null;
        if (name) {
            const Id = await getIdTipoTramite(name);
            if (Id) {
                const url = `encuadreTipoTramiteUltimosDias?dias=30&tipoTramite=${Id}`;
                exportToCSV(
                    () => fetchListado(url),
                    `encuadre_tramite_${name}`
                );
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Prospectos en Encuadre</h1>
            </div>
            <div className='mitad'>
                {encuadresUltimosDias && (
                    <ListadoChart
                        data={formatData(encuadresUltimosDias.slice(0, 2))}
                        progressBar={false}
                        subtitle={`Antiguedad Promedio ${encuadresUltimosDias[2].count} días`}
                        onClickFn={ExcelListadosAntiguedad}
                    />
                )}
                {encuadresPorTipoTramite && (
                    <PieChartsJS
                        title='Por Tipo'
                        dataValues={encuadresPorTipoTramite.map(
                            (tp) => tp.count
                        )}
                        labels={encuadresPorTipoTramite.map(
                            (tp) => tp.TipoTramite
                        )}
                        onClickFn={ExcelListadoTipoTramite}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardProspectosEncuadre;
