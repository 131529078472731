import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../css/dashboard.css";
import "../../css/swiper.css";

import DashboardPrincipal from "./principal/principal.component";
import DashboardPrincipalProspectos from "./principal/prospectos.component";
import DashboardConsultasNoConducentes from "./consultas-no-conducentes.component";
import DashboardProspectosPendientes from "./prospectos/prospectos-pendientes.component";
import DashboardPrincipalCierres from "./principal/cierres.component";
import DashboardPrincipalDesempeño from "./principal/desempeno.component";
import DashboardPrincipalObjetivos from "./principal/objetivos.components";
import { useState } from "react";
import DashboardProspectosEncuadre from "./prospectos/prospectos-encuadre.component";
import DashboardProspectosBaja from "./prospectos/prospectos-baja.component";
import DashboardProspectosStandBy from "./prospectos/prospectos-standby.component";
import DashboardOrigenClientes from "./origen-clientes.component";
import DashboardDesempenoAsesor from "./desempeno-asesor.component";
import DashboardDatosHistoricos from "./datos-historicos.component";
import { SwiperContext } from "../../provider/swiper/swiper.provider";

SwiperCore.use([Navigation, Pagination]);

const NuevoDashboardContainer = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiper, setSwiper] = useState();
    const swipe = (index) => {
        swiper.slideTo(index);
    };
    const dashPages = [
        {
            titulo: "Contactos",
            componente: <DashboardPrincipal swipe={swipe} />,
        },
        {
            titulo: "No Conducentes",
            componente: <DashboardConsultasNoConducentes />,
        },
        {
            titulo: "Prospectos",
            componente: <DashboardPrincipalProspectos swipe={swipe} />,
        },
        {
            titulo: "Prospectos Pendientes",
            componente: <DashboardProspectosPendientes />,
        },
        {
            titulo: "Prospectos Encuadre",
            componente: <DashboardProspectosEncuadre />,
        },
        {
            titulo: "Prospectos Stand By",
            componente: <DashboardProspectosStandBy />,
        },
        { titulo: "Prospectos Baja", componente: <DashboardProspectosBaja /> },
        {
            titulo: "Cierres",
            componente: <DashboardPrincipalCierres swipe={swipe} />,
        },
        { titulo: "Origen Clientes", componente: <DashboardOrigenClientes /> },
        {
            titulo: "Objetivos Mensuales",
            componente: <DashboardPrincipalObjetivos />,
        },
        {
            titulo: "Desempeño Equipo",
            componente: <DashboardPrincipalDesempeño swipe={swipe} />,
        },
        {
            titulo: "Datos Históricos",
            componente: <DashboardDatosHistoricos />,
        },
        {
            titulo: "Desempeño Usuario",
            componente: <DashboardDesempenoAsesor swipe={swipe} />,
            noDisplay: true,
        },
    ];
    //Esto vamos a tener que hacerlo distinto, posiblemente un navbar o no se, pero distinto xq queda muy grande con 13 tabs.
    //Quiza no mostrar alguno que se llegue haciendo click?
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            if (dashPages[index].noDisplay) return [];
            return `<span class="${className}">${
                dashPages[index].titulo || index + 1
            }</span>`;
        },
    };

    // Para pasar parametro en el dashboard
    const [activeID, setActiveID] = useState(0);

    return (
        <SwiperContext.Provider
            value={{
                activeID,
                setActiveID,
            }}>
            <Swiper
                pagination={pagination}
                navigation={true}
                className='mySwiper'
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => {
                    setSwiper(swiper);
                }}
                onSlideChange={(swiper) => {
                    //Todo aca hay un tema con el activeIndex, cuando intenta ingresar a Datos Historicos
                    //Como el item anterior no se hace render, en teoria de "Desempeño Equipo" deberia pasar a "Datos Historicos", pero hace un quilombazo.
                    setActiveIndex(swiper.activeIndex);
                }}>
                {dashPages.map((p, ix) => (
                    <SwiperSlide key={p.titulo}>
                        {activeIndex === ix ? p.componente : null}
                    </SwiperSlide>
                ))}
            </Swiper>
        </SwiperContext.Provider>
    );
};

export default NuevoDashboardContainer;
