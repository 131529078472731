import React, { Fragment, useMemo } from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Row,
    Table
} from "react-bootstrap";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { azulEnGrilla } from "../../provider/required/required.context";
import ColumnLabel from "../column-label/column-label.component";

const TablaReact = ({ columnas, datos }) => {
	// eslint-disable-next-line
	const columns = useMemo(() => columnas, []);

	// eslint-disable-next-line
	const data = useMemo(() => datos, []);

	const defaultColumn = useMemo(() => {
		return {
			// Filter: DefaultColumnFilter,
			disableFilters: true,
			width: "auto",
		};
	}, []);
	const getRowProps = (row) => {
		const backgroundPar = "#f5f5f5";
		const backgroundImpar = "white";
		const backgrounndPrioritario = "red";
		const backgroundMoratoria = "#6CB4EE";
		let color = "rgb(102, 102, 102)";
		let background = backgroundImpar;
		if (row) {
			//Esto debería ir a mongo como config
			if (row.original.faltaDocumentacion) {
				background = "yellow";
				color = "white";
			} else if (row.original.reajusta) {
				background = "green";
				color = "white";
			} else if (row.original.noReajusta) {
				background = "red";
				color = "white";
			} else if (row.values.prioritario) {
				background = backgrounndPrioritario;
				color = "white";
			} else if (
				azulEnGrilla(row.values.tipoTramite, row.values.estado)
			) {
				background = backgroundMoratoria;
				color = "white";
			} else if (row.id % 2 === 0) background = backgroundPar;
			// console.log(row.values.prioritario, row.id, background);
			return {
				style: {
					background,
					color,
				},
			};
		}
	};
	const Tabla = () => {
		// Use the state and functions returned from useTable to build your UI
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			nextPage,
			previousPage,
			canNextPage,
			canPreviousPage,
			pageOptions,
			state,
			gotoPage,
			pageCount,
			setPageSize,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				defaultColumn,
				initialState: {
					pageIndex: 0,
					sortBy: [
						{
							id: "fechaCreacion",
							desc: true,
						},
					],
					hiddenColumns: ["prioritario"],
				},
			},
			useFilters,
			useSortBy,
			usePagination
		);
		const { pageIndex, pageSize } = state;
		// Render the UI for your table
		return (
			<Fragment>
				<Table
					width="100%"
					className="table-condensed"
					bordered
					{...getTableProps()}
				>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps(
											column.getSortByToggleProps()
										)}
									>
										<Container fluid>
											<Row style={{ marginBottom: "0" }}>
												<Col>
													<Form.Label size="lg">
														{column.render(
															"Header"
														)}
														{column.isSorted
															? column.isSortedDesc
																? "▼"
																: "▲"
															: ""}
													</Form.Label>
												</Col>
											</Row>
											<Row>
												<Col className="justify-content-end">
													{column.canFilter ? (
														column.render("Filter")
													) : (
														<p>&nbsp;</p>
													)}
												</Col>
											</Row>
										</Container>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps(getRowProps(row))}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>
												<ColumnLabel>
													{cell.render("Cell")}
												</ColumnLabel>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
				<Container fluid>
					<Row className="justify-content-end">
						<Col className="text-right">
							<Form.Label className="mr-1">
								Página:{" "}
								<strong>
									{pageIndex + 1} / {pageOptions.length}
								</strong>
							</Form.Label>
							<Form.Label className="mr-1">
								Ir a página:{" "}
								<input
									type="number"
									defaultValue={pageIndex + 1}
									onChange={(e) => {
										const pageNumber = e.target.value
											? Number(e.target.value) - 1
											: 0;
										gotoPage(pageNumber);
									}}
									style={{ width: "50px" }}
								/>
							</Form.Label>
							<Form.Label className="mr-1">Mostrar </Form.Label>
							<select
								value={pageSize}
								className="mr-1"
								onChange={(e) =>
									setPageSize(Number(e.target.value))
								}
							>
								{[10, 25, 50].map((pageSize) => {
									return (
										<option key={pageSize} value={pageSize}>
											{pageSize}
										</option>
									);
								})}
								‮
							</select>
							<Form.Label className="mr-1">elementos </Form.Label>
							<ButtonGroup>
								<Button
									variant="info"
									onClick={() => gotoPage(0)}
									disabled={!canPreviousPage}
								>
									{"◀◀"}
								</Button>
								<Button
									variant="info"
									onClick={() => previousPage()}
									disabled={!canPreviousPage}
								>
									{"◀"}
								</Button>
								<Button
									variant="info"
									onClick={() => nextPage()}
									disabled={!canNextPage}
								>
									{"▶"}
								</Button>
								<Button
									variant="info"
									onClick={() => gotoPage(pageCount - 1)}
									disabled={!canNextPage}
								>
									{"▶▶"}
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</Container>
			</Fragment>
		);
	};
	return <Tabla />;
};

export default TablaReact;
