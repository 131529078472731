import { GetColorFalcone, MapNombresTraduccion } from "./consts";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Get } from "./fetch";

export const getIdTipoTramite = async (tipoTramite) => {
    const objTipoTramites = await Get(`tipoTramite`);
    if (objTipoTramites) {
        const { Id } = objTipoTramites.find(
            (tt) => tt.Descripcion === tipoTramite
        );
        return Id;
    }
    return null;
};
//Devuelve un objeto como un array de objetos
//[ { Descripcion: 'xxxx', count: 11111}]
export const createFormatableObject = (obj, traduccionByPeriodo) => {
    const fnTraduccion = !traduccionByPeriodo
        ? getTraduccion
        : getTraduccionByPeriodo;
    return Object.entries(obj).map((d) => {
        return {
            Descripcion: fnTraduccion(d[0], traduccionByPeriodo),
            count: d[1],
        };
    });
};
export const formatData = (
    data,
    nameField = "Descripcion",
    valueField = "count"
) => {
    return data.map((o, i) => ({
        name: o[nameField],
        value: o[valueField],
    }));
};
export const formatDataWithId = (
    data,
    nameField = "Descripcion",
    valueField = "count",
    idField = "id"
) => {
    return data.map((o, i) => ({
        name: o[nameField],
        value: o[valueField],
        id: o[idField],
    }));
};
export const fetchListado = async (listado) => {
    const data = await Get(`listados/${listado}`);
    if (data.ok) return data.obj;
    return null;
};
export const getColors = (data) => {
    return data.map((d, ix) => GetColorFalcone((1 / data.length) * ix));
};
export const getColorsHex = (data) => {
    return getColors(data).map((c) => c.hex());
};
export const getTraduccion = (campo) => {
    const { traduccion } =
        MapNombresTraduccion.find((t) => t.nombre === campo) || {};
    return traduccion || null;
};
export const getTraduccionByPeriodo = (campo, periodo) => {
    const { traduccion } =
        MapNombresTraduccion.find(
            (t) => t.periodo === periodo && t.nombre === campo
        ) || {};
    return traduccion || getTraduccion(campo) || null;
};

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (fetchData, fileName) => {
    fileName = fileName.length > 28 ? fileName.substr(0, 28) : fileName;
    const modal = waitingDiv(`Descargando ${fileName + fileExtension}`);
    fetchData().then((listado) => {
        modal.parentNode.removeChild(modal);
        if (listado) {
            const ws = XLSX.utils.json_to_sheet(listado);
            const wb = {
                Sheets: { [fileName]: ws },
                SheetNames: [fileName],
            };
            const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, escape(fileName) + fileExtension);
        } else return false;
    });
};
function waitingDiv(msg) {
    var alt = document.createElement("div");
    alt.setAttribute("class", "workingModal");
    alt.innerHTML = `<span>${msg}</span>`;
    document.body.appendChild(alt);
    return alt;
}

export const getChartLegend = (legendPosition, display) => ({
    display: display ? true : false,
    position: legendPosition,
    labels: {
        usePointStyle: true,
        pointStyle: "circle",
        font: {
            family: "Alegreya",
            size: 11,
        },
    },
});

export const getChartTitle = (title, displayPosition = "top") => ({
    display: true,
    fullSize: true,
    text: title,
    position: displayPosition,
    font: {
        family: "Alegreya",
        size: 30,
        weight: 900,
    },
});
export const getChartOptions = (legend, title, properties) => ({
    ...properties,
    responsive: true,
    plugins: {
        legend,
        title,
        datalabels: {
            display: false,
        },
    },
});
