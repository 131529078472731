import GaugeChart from "react-gauge-chart";

const VelocimetroChart = ({ porcentaje, titulo, valor }) => {
    const getClassName = (valor) => {
        valor = Number.parseInt(valor);
        if (valor < 0) return "rojo";
        if (valor > 0) return "verde";
    };
    return (
        <div className='velocimetro'>
            <GaugeChart
                nrOfLevels={6}
                percent={porcentaje}
                colors={["#FF3131", "#FF5F1F", "#39ff14"]}
                hideText={true}
                marginInPercent={0.01}
                arcPadding={0.02}
                style={{ width: 150 }}
                needleBaseColor={"red"}
            />
            <h4 className='titulo'>{titulo}</h4>
            <h4 className={`${getClassName(valor)} grande`}>{valor}</h4>
        </div>
    );
};

export default VelocimetroChart;
