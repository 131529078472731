import { useEffect, useState } from "react";
import BarChartJS from "../../componentes/bar-chartjs/bar-chartjs.component";
import { MESES } from "../../scripts/consts";
import { Get } from "../../scripts/fetch";

// 123
const DashboardDatosHistoricos = () => {
    const [evolucionCierres, setevolucionCierres] = useState();
    const [tiposTramite, setTiposTramite] = useState();

    useEffect(() => {
        const getDatosHistoricos = async () => {
            Get(`charts/getEvolucionCierres?meses=12`).then((d) => {
                setTiposTramite(
                    d.obj.reduce((cur, acc) => {
                        if (!cur.find((c) => c === acc.tipoTramite))
                            cur.push(acc.tipoTramite);
                        return cur;
                    }, [])
                );
                setevolucionCierres(d.obj);
            });
        };

        getDatosHistoricos();
    }, []);

    const getDatos = (tipoTramite) => {
        if (!evolucionCierres) return [];
        const ret = evolucionCierres.filter(
            (ec) => ec.tipoTramite === tipoTramite
        );

        return ret.map((ev) => {
            return {
                ...ev,
                mes: MESES[ev.mes],
            };
        });
    };

    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Datos Históricos</h1>
            </div>
            <div className='gridCuatroColumnas'>
                {!tiposTramite && !evolucionCierres
                    ? null
                    : tiposTramite.map((t) => {
                          const data = getDatos(t);
                          return (
                              <BarChartJS
                                  labels={data.map((d) => d.mes)}
                                  dataValues={data.map((d) => d.count)}
                                  legend={t}
                                  key={t}
                              />
                          );
                      })}
            </div>
        </div>
    );
};

export default DashboardDatosHistoricos;
