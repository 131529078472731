// import { useState } from "react";
import { useHistory } from "react-router-dom";

// import { Form, Container, Button } from "react-bootstrap";

import { useContext, useEffect } from "react";
import { UserContext } from "../../provider/user/user.provider";

import { Get } from "../../scripts/fetch";
import { MensajeError } from "../../scripts/mensajePantalla";

import qs from "qs";
// import NuevoDashboardContainer from "../nuevoDashboard/dashboard.container";
// import { SpinnerContainer } from "../../componentes/spinner/with-spinner.styles";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "../../componentes/spinner/with-spinner.component";

const Login = () => {
	const { loginUser } = useContext(UserContext);
	let history = useHistory();
	const { alias } = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	});
	const { goto, id } = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	});

	const gotoMap = [
		{ original: "Configuracion", redirect: "Config", super: true },
		{ original: "Resumen", redirect: "Dashboard", super: true },
		{ original: "Crear", redirect: "Ingreso", super: false },
		{ original: "ResumenNuevo", redirect: "DashboardNuevo", super: true },
		{ original: "HomeBO", redirect: "HomeBO", super: false },
		{ original: "Asignacion", redirect: "Asignacion", super: false },
	];
	useEffect(() => {
		const doLoginUser = (user) => {
			if (user.userName && user.userRol && user.userAlias && user.userId) {
				loginUser(user);
				let url = "Home";
				if (goto) {
					const redirect = gotoMap.find(
						(g) =>
							g.original.toLowerCase() === goto.toLowerCase() &&
							(!g.super || user.userSuperUsuario)
					);
					url = redirect ? redirect.redirect : "Home";
				}
				// console.log(url);
				//Esto es para emular el click en el link.
				const finalUrl = {
					pathname: `/${url}`,
					id,
				};
				history.push(finalUrl);
			} else {
				console.log(user);
				MensajeError(
					"Usuario",
					`Ocurrio un error con el usuario ${user.userName}`
				);
			}
		};
		if (alias) {
			Get(`usuario/${alias}`).then((user) => {
				//esto se ejecuta en cada update, quiza ver de cachearlo para no ir 200 veces a la bdd.
				if (user.ok) {
					setTimeout(() => {
						doLoginUser(user.obj);
					}, 500);
				} else
					MensajeError("Usuario", `Error al buscar el usuario. ${user.err}`);
			});
		}
		// eslint-disable-next-line
	}, [alias]);
	return (
		<Container>
			<Row>
				<Col>
					{alias ? (
						<Spinner isLoading={true} />
					) : (
						<h2>Ingrese a traves del BackOffice para continuar</h2>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
