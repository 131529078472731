import { useEffect, useState } from "react";
import { ListadoChart } from "../../componentes/listadochart/ListadoChart";
import { Get } from "../../scripts/fetch";
import { formatData } from "../../scripts/chartScripts";
import PieChartsJS from "../../componentes/pie-chartjs/pie-chartjs.component";
// import PieOutlabels from "../../componentes/pie-chartjs/pie-outlabel.component";

const DashboardConsultasNoConducentes = () => {
    const [motivosNoConducentes, setmotivosNoConducentes] = useState();
    const [canalIngresoNoConducente, setcanalIngresoNoConducente] = useState();
    useEffect(() => {
        const getMotivoNoConducentes = async () => {
            Get(`charts/motivosNoConducentes`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setmotivosNoConducentes(d.obj);
            });
        };
        const getCanalIngresoNoConducentes = async () => {
            Get(`charts/canalIngresoNoConducentes`).then((d) => {
                setcanalIngresoNoConducente(d.obj);
            });
        };
        getMotivoNoConducentes();
        getCanalIngresoNoConducentes();
    }, []);
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Detalle de Consulta No Conducentes</h1>
            </div>
            <div className='mitad'>
                {canalIngresoNoConducente && (
                    <ListadoChart
                        data={formatData(canalIngresoNoConducente)}
                        progressBar={true}
                    />
                )}
                {motivosNoConducentes && (
                    <>
                        <PieChartsJS
                            labels={motivosNoConducentes.map(
                                (p) => p.Descripcion
                            )}
                            dataValues={motivosNoConducentes.map(
                                (p) => p.count
                            )}
                            title=" "
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardConsultasNoConducentes;
