import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
// import { SelectColumnFilter } from "../scripts/tableScripts"
export class ObjConfigColumns {
    constructor(model, modelName){
        if(!model) return;
        this.Model = modelName;
        // console.log('Model in constructor -->',model);
        this.Columns = Object.entries(model).map(entry => {
            if(!entry) return null;
            if(entry[0] === '_id' || entry[0] === '__v') return null;
            return {
                Header: entry[0],
                accessor: entry[0]
            }
        })
        this.Columns = this.Columns.filter(el => el);
    }
    Columnas = fnClickDelete =>  {
        if(! this.Columns) return [];
        return [
            ...this.Columns, 
            {
                Header: "",
                accessor: "_id",
                Cell: ({ cell: { value } }) => (
                    <div className='d-flex justify-content-end'>
                        <Link
                            to={{ pathname: `/Config/${this.Model}/${value}`, id: value }}
                            className='btn btn-mini btn-success'>
                            <i className='icon-edit icon-white' />
                        </Link>
                        <Button
                            variant='link'
                            className='btn btn-mini btn-danger'
                            onClick={() => fnClickDelete(value)}>
                            <i className='icon-trash icon-white' />
                        </Button>
                    </div>
                ),
            },
        ];
    }
}