import { useEffect, useState } from "react";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../../componentes/pie-chartjs/pie-chartjs.component";
import { formatData } from "../../../scripts/chartScripts";
import { Get } from "../../../scripts/fetch";

const DashboardPrincipalProspectos = ({ swipe }) => {
    const [estadosProspectos, setestadosProspectos] = useState();
    const [cierresProspectos, setcierresProspectos] = useState();
    //esto seguramente termine en otro lado, lo mismo q la fn
    const ordenEstados = [
        { name: "Asignación", swipeIX: 3 },
        { name: "Encuadre", swipeIX: 4 },
        { name: "Stand By", swipeIX: 5 },
        { name: "Baja", swipeIX: 6 },
        { name: "Cierre", swipeIX: 7 },
    ];
    useEffect(() => {
        const sortPorEstado = (data, descripcionField = "_id") =>
            data.sort(
                (a, b) =>
                    ordenEstados.findIndex(
                        (oe) => oe.name === a[descripcionField]
                    ) -
                    ordenEstados.findIndex(
                        (oe) => oe.name === b[descripcionField]
                    )
            );
        const getEstadosProspectos = async () => {
            Get(`charts/estadosProspectos`).then((d) => {
                setestadosProspectos(sortPorEstado(d.obj));
            });
        };
        //Ahora que lo veo me parece que no es esto....
        const getCierresProspectos = async () => {
            Get(`charts/cierresPorOrigen`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setcierresProspectos(d.obj);
            });
        };
        getEstadosProspectos();
        getCierresProspectos();
        //eslint-disable-next-line
    }, []);
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Prospectos</h1>
            </div>
            <div className='mitad'>
                {estadosProspectos && (
                    <ListadoChart
                        data={formatData(estadosProspectos, "_id")}
                        onClickFn={(entry) => {
                            console.log(entry);
                            swipe(
                                ordenEstados.find(
                                    (oe) => oe.name === entry.name
                                ).swipeIX
                            );
                        }}
                        progressBar={false}
                    />
                )}
                {cierresProspectos && (
                    <PieChartsJS
                        labels={cierresProspectos.map((p) => p.canalDeIngreso)}
                        dataValues={cierresProspectos.map((p) => p.count)}
                        title='Origen'
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardPrincipalProspectos;
