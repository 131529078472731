import { Fragment } from "react";
import { SpinnerContainer, SpinnerOverlay } from "./with-spinner.styles";

const Spinner = ({ isLoading }) => {
    return isLoading ? (
        <SpinnerOverlay>
            <SpinnerContainer />
        </SpinnerOverlay>
    ) : (
        <Fragment />
    );
};

export default Spinner;
