import { createContext, useState } from "react";

export const UserContext = createContext({
    user: "",
    loginUser: () => {},
    userLoggedIn: () => {},
});
const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState({
        userName: "",
        userId: 0,
        userAlias: "",
        userRol: 0,
        userSuperUsuario: false,
    });
    const [lastLogin, setlastLogin] = useState(undefined);

    const loginUser = (userProps) => {
        setUser({ ...userProps });
        setlastLogin(new Date());
    };
    const userLoggedIn = () => {
        //1 hora?
        return user.userName && (new Date() - lastLogin) / 1000 / 60 < 60;
    };
    return (
        <UserContext.Provider
            value={{
                user,
                userLoggedIn,
                loginUser,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
