import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

export const ListadoChart = ({
    data = [],
    //
    onClickFn,
    progressBar = true,
    title = null,
    subtitle = null,
}) => {
    const total = () => data.reduce((acc, cur) => acc + cur.value, 0);
    const getProgressBarValue = (value) => Math.round((value * 100) / total());
    const getValue = (value) => {
        if (!progressBar) return value;
        return `${Math.round((value * 100) / total())}%`;
    };
    return (
        <div className='card'>
            <div className='card-body'>
                {title && <h1 className='listadoTitle'>{title}</h1>}
                {data.map((entry, index) => (
                    <div
                        className={`grillaListadoDashboard ${
                            progressBar
                                ? "grillaTablaProgressBar"
                                : "grillaTabla"
                        } ${onClickFn && "clickable"}`}
                        key={entry.name}
                        onClick={() => {
                            if (onClickFn) onClickFn(entry);
                        }}>
                        <span>{entry.name}</span>
                        {progressBar && (
                            <span>
                                <ProgressBar
                                    className='progressBarEnGrid'
                                    now={getProgressBarValue(entry.value)}
                                />
                            </span>
                        )}
                        <span className='valueEnGrid'>
                            {getValue(entry.value)}
                        </span>
                    </div>
                ))}
                {subtitle && <div className='subtitle'>{subtitle}</div>}
            </div>
        </div>
    );
};
