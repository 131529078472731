import { useEffect, useState } from "react";
import LineChart from "../../../componentes/line-chartjs/line-chartjs.component";
import VariacionInteranual from "../../../componentes/variacion-interanual/variacion-interanual.component";
import { MESES } from "../../../scripts/consts";
import { Get } from "../../../scripts/fetch";

const diasMesActual = new Date().getDate() - 1; // Para calcular el inicio de mes

const DashboardPrincipal = ({ swipe }) => {
    const [totalConducentes, setTotalConducentes] = useState();
    const [evolucionConducentes, setEvolucionConducentes] = useState();
    const [variacionInteranual, setVariacionInteranual] = useState();
    useEffect(() => {
        const getTotalConducentes = async () => {
            Get(`charts/totalConducentes?dias=${diasMesActual}`).then((d) => {
                // console.log(d.obj);
                setTotalConducentes(d.obj);
            });
        };
        const getEvolucionConducentes = async () => {
            Get(`charts/evolucionConducentes`).then((d) => {
                // console.log(d.obj);
                setEvolucionConducentes(
                    d.obj
                        .sort((a, b) => {
                            return a.mes - b.mes;
                        })
                        .map((e) => {
                            return {
                                ...e,
                                mes: MESES[e.mes],
                            };
                        })
                );
            });
        };
        const getVariacionInteranual = async () => {
            Get(`charts/variacionInteranual`).then((d) => {
                console.log(d.obj);
                setVariacionInteranual(d.obj);
            });
        };
        getTotalConducentes();
        getEvolucionConducentes();
        getVariacionInteranual();
    }, []);

    const mesActual = MESES[new Date().getMonth() + 1];
    const {
        conducentes = 0,
        noConducentes = 0,
        conducentesPeriodo = 0,
        noConducentesPeriodo = 0,
    } = totalConducentes || {};
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>{mesActual}</h1>
            </div>
            <div className='chartTitle'>
                <h2>Contactos</h2>
            </div>
            <div className='charts'>
                <div className='dobleChart'>
                    <div>
                        <h3 className='numeroPrincipal'>
                            {conducentesPeriodo + noConducentesPeriodo}
                        </h3>
                    </div>
                    <div>
                        <h4
                            className='subNumeroPrincipal bordeArriba clickable'
                            onClick={() => swipe(1)}>
                            {noConducentesPeriodo} no conducentes
                        </h4>
                        <h4
                            className='subNumeroPrincipal'
                            onClick={() => swipe(1)}>
                            {conducentesPeriodo} conducentes
                        </h4>
                    </div>
                </div>
                <div className='dobleChart'>
                    <div>
                        {variacionInteranual && (
                            <VariacionInteranual
                                variacionInteranual={variacionInteranual}
                            />
                        )}
                    </div>
                    <div>
                        <h3 className='numeroPrincipal bordeArriba'>
                            {conducentes + noConducentes}
                        </h3>
                        <h4 className='subNumeroPrincipal'>
                            Consultas totales atendidas
                        </h4>
                    </div>
                </div>
                <div>
                    {evolucionConducentes && (
                        <LineChart
                            labels={evolucionConducentes.map((p) => p.mes)}
                            dataValues={[
                                {
                                    label: "Conducentes",
                                    values: evolucionConducentes.map(
                                        (p) => p.conducentes
                                    ),
                                },
                                {
                                    label: "No Conducentes",
                                    values: evolucionConducentes.map(
                                        (p) => p.noConducentes
                                    ),
                                },
                            ]}
                            legend='Conducentes'
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardPrincipal;
