import { useEffect, useState } from "react";
import BarChartJS from "../../../componentes/bar-chartjs/bar-chartjs.component";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../../componentes/pie-chartjs/pie-chartjs.component";
import {
    createFormatableObject,
    exportToCSV,
    fetchListado,
    formatData,
    getIdTipoTramite,
    getTraduccionByPeriodo,
} from "../../../scripts/chartScripts";
import { Get } from "../../../scripts/fetch";
import { MensajeError } from "../../../scripts/mensajePantalla";

const DashboardProspectosPendientes = () => {
    const [pendientesUltimosDias, setpendientesUltimosDias] = useState();
    const [pendientesPorAsesor, setpendientesPorAsesor] = useState();
    const [pendientesPorTipoTramite, setpendientesPorTipoTramite] = useState();

    useEffect(() => {
        const getPendientesUltimosDias = async () => {
            Get(`charts/pendientesUltimosDias?dias=30`).then((d) => {
                setpendientesUltimosDias(
                    createFormatableObject(d.obj, "semana")
                );
            });
        };
        const getPendientesPorAsesor = async () => {
            Get(`charts/pendientesPorAsesor?dias=30`).then((d) => {
                setpendientesPorAsesor(d.obj);
            });
        };
        const getPendientesPorTipoTramite = async () => {
            Get(`charts/pendientesPorTipoTramite?dias=30`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setpendientesPorTipoTramite(d.obj);
            });
        };
        getPendientesPorAsesor();
        getPendientesUltimosDias();
        getPendientesPorTipoTramite();
    }, []);
    const ExcelListadosAntiguedad = (entry) => {
        let url = "pendientesAnterioresUltimosDias";
        let fileName = "prospectos_pendientes_antiguos";
        if (
            entry.name ===
            getTraduccionByPeriodo("cantidadUltimosDias", "semana")
        ) {
            url = "pendientesCreadosUltimosDias";
            fileName = "prospectos_pendientes_semanales";
        }
        exportToCSV(() => fetchListado(url), fileName);
    };
    const ExcelListadosPorTipoTramite = async (entry) => {
        const { name } = entry || null;
        if (name) {
            const Id = await getIdTipoTramite(name);
            if (Id) {
                const url = `pendientesTipoTramiteUltimosDias?dias=30&tipoTramite=${Id}`;
                exportToCSV(
                    () => fetchListado(url),
                    `pendientes_tramite_${name}`
                );
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };
    const ExcelListadosAsesor = async (entry) => {
        const { name: asesor } = entry;
        const objAsesor = await Get(`usuario/${asesor.split(" ")[0]}`); //Se busca por el alias, no se bien de donde salio el NyA
        if (objAsesor.ok) {
            const { userId, userAlias } = objAsesor.obj;
            const url = `pendientesAsesoresUltimosDias?dias=30&asesor=${userId}`;
            exportToCSV(
                () => fetchListado(url),
                `pendientes_asesor_${userAlias}`
            );
        } else
            MensajeError(
                "Ocurrio un error al buscar el usuario, intentelo nuevamente más tarde."
            );
    };
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Prospectos Pendientes</h1>
            </div>
            <div className='grid tricota'>
                {pendientesUltimosDias && (
                    <ListadoChart
                        data={formatData(pendientesUltimosDias.slice(0, 2))}
                        subtitle={`${pendientesUltimosDias[2].Descripcion} ${pendientesUltimosDias[2].count} días`}
                        onClickFn={ExcelListadosAntiguedad}
                        progressBar={false}
                    />
                )}
                {pendientesPorAsesor && (
                    <BarChartJS
                        labels={pendientesPorAsesor.map((pa) => pa.Asesor)}
                        dataValues={pendientesPorAsesor.map((pa) => pa.count)}
                        // title='Pendientes por Asesor'
                        onClickFn={ExcelListadosAsesor}
                    />
                )}
                {pendientesPorTipoTramite && (
                    <PieChartsJS
                        labels={pendientesPorTipoTramite.map(
                            (p) => p.tipoTramite
                        )}
                        dataValues={pendientesPorTipoTramite.map(
                            (p) => p.count
                        )}
                        title='Por Tipo'
                        onClickFn={ExcelListadosPorTipoTramite}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardProspectosPendientes;
