import { Bar } from "react-chartjs-2";
import {
    getChartLegend,
    getChartOptions,
    getChartTitle,
    getColorsHex,
} from "../../scripts/chartScripts";

const BarChartJS = ({
    labels,
    dataValues,
    dataSets = null,
    title: titulo,
    legend: leyenda,
    legendPosition,
    onClickFn,
}) => {
    const data = {
        labels,
        datasets: dataSets || [
            {
                data: dataValues,
                backgroundColor: getColorsHex(labels),
                borderWidth: 1,
            },
        ],
    };
    const getDatasetAtEvent = (element) => {
        if (!element.length) return;
        const { datasetIndex, index } = element[0];
        if (onClickFn)
            onClickFn({
                index,
                name: data.labels[index],
                value: data.datasets[datasetIndex].data[index],
            });
    };
    const legend = getChartLegend(legendPosition, false);
    const title = titulo && getChartTitle(titulo, "top");
    const options = getChartOptions(legend, title, {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    });

    return (
        <div className='chartJSContainer'>
            <Bar
                data={data}
                getElementAtEvent={getDatasetAtEvent}
                options={options}
            />
            {leyenda && (
                <div className='chartJsLegend'>
                    <h5>{leyenda}</h5>
                </div>
            )}
        </div>
    );
};

export default BarChartJS;
