import { useEffect, useState } from "react";
import { ListadoChart } from "../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../componentes/pie-chartjs/pie-chartjs.component";
import { formatData } from "../../scripts/chartScripts";
import { Get } from "../../scripts/fetch";

const DashboardOrigenClientes = () => {

    const [cierresOrigen, setcierresOrigen] = useState();
    const [cierresReferidos, setcierresReferidos] = useState();

    useEffect(() => {

        const getCierresPorOrigen = async () => {
            Get(`charts/cierresPorOrigen`).then( (d) => { 
                setcierresOrigen(d.obj) 
            });
        };

        const getCierresPorReferido = async () => {
            Get(`charts/cierresPorReferidos`).then( (d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setcierresReferidos(d.obj) 
            });
        };

        getCierresPorOrigen();
        getCierresPorReferido();

    }, []);

    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Origen de los Clientes</h1>
            </div>
            <div className='mitad'>
                {cierresOrigen && (
                    <ListadoChart
                        data={ formatData(cierresOrigen, "canalDeIngreso") }
                        onClickFn={(entry) => {
                            console.log(entry);
                        }}
                        progressBar={ true }
                        title='Canal de ingreso'
                    />
                )}
                {cierresReferidos && (
                    <PieChartsJS
                        labels={cierresReferidos.map((p) => p.canalContacto)}
                        dataValues={cierresReferidos.map((p) => p.count)}
                        title='¿Cómo nos conocio?'
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardOrigenClientes;
