import { Line } from "react-chartjs-2";
import {
    getChartLegend,
    getChartOptions,
    getChartTitle,
    getColorsHex,
} from "../../scripts/chartScripts";

const LineChart = ({
    labels,
    dataValues,
    title: titulo,
    titlePosition = "top",
    legend: leyenda,
    legendPosition = "bottom",
    onClickFn,
}) => {
    const data = {
        labels,
        datasets: dataValues.map((dv, ix) => ({
            label: dv.label,
            data: dv.values,
            backgroundColor: getColorsHex(dv.values)[ix],
            borderWidth: 1,
        })),
    };

    const legend = leyenda && getChartLegend(legendPosition);

    const title = titulo && getChartTitle(titulo, titlePosition);

    const options = getChartOptions(legend, title);

    return (
        <div className='chartJSContainer'>
            <Line data={data} options={options} />
        </div>
    );
};

export default LineChart;
