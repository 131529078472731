import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
    getChartLegend,
    getChartOptions,
    getChartTitle,
    getColorsHex,
} from "../../scripts/chartScripts";

// import { useState } from "react";
// import SwitchMaterial from "../switch/switch.component";

const PieChartsJS = ({
    labels,
    dataValues,
    title: titulo,
    legend: leyenda,
    legendPosition = "bottom",
    onClickFn,
}) => {
    // const [showLegend, setshowLegend] = useState(true);
    const showLegend = true;
    const data = {
        labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: getColorsHex(labels),
                borderWidth: 1,
            },
        ],
    };

    const totalCount = data.datasets[0].data.reduce((acc, cur) => acc + cur, 0);

    const getLabel = (lbl, val) =>
        `${lbl} - ${Math.round((val * 100) / totalCount)}%`;

    const getDatasetAtEvent = (element) => {
        if (!element.length) return;
        const { datasetIndex, index } = element[0];
        if (onClickFn)
            onClickFn({
                index,
                name: data.labels[index],
                value: data.datasets[datasetIndex].data[index],
            });
    };
    const title = titulo && getChartTitle(titulo);
    const legend = getChartLegend(legendPosition, showLegend);
    legend.labels.generateLabels = (chart) => {
        const data = chart.data;
        if (data.labels.length && data.datasets.length) {
            const {
                labels: { pointStyle },
            } = chart.legend.options;
            return data.labels.map((label, i) => {
                const val = data.datasets[0].data[i];
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                return {
                    text: getLabel(label, val),
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                };
            });
        }
    };

    // const datalabels = {
    //     backgroundColor: function (context) {
    //         return context.dataset.backgroundColor;
    //     },
    //     borderColor: "white",
    //     borderRadius: 25,
    //     borderWidth: 2,
    //     color: "white",
    //     display: function (context) {
    //         var dataset = context.dataset;
    //         var count = dataset.data.length;
    //         var value = dataset.data[context.dataIndex];
    //         return value > count * 3;
    //     },
    //     font: {
    //         weight: "bold",
    //     },
    //     padding: 6,
    //     // formatter: Math.round,
    //     formatter: function (value, context) {
    //         return labels[context.dataIndex];
    //     },
    // };

    const options = getChartOptions(legend, title, { cutout: "70%" });
    return (
        <div className='chartJSContainer'>
            <Doughnut
                getElementAtEvent={getDatasetAtEvent}
                data={data}
                plugins={[ChartDataLabels]}
                options={options}
            />
            {/* Este control anda bien PERO si hay varios en una misma pantalla, solo switchea el primero, por ahora lo saco */}
            {/* <SwitchMaterial
                checked={showLegend}
                onClick={(chk) => setshowLegend(chk)}
                label='Leyenda'
            /> */}
            {/* No se si esto se esta usando */}
            {leyenda && (
                <div className='chartJsLegend'>
                    <h5>{leyenda}</h5>
                </div>
            )}
        </div>
    );
};

export default PieChartsJS;
