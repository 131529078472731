import { useEffect, useState } from "react";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../../componentes/pie-chartjs/pie-chartjs.component";
import {
    createFormatableObject,
    exportToCSV,
    fetchListado,
    formatData,
    getIdTipoTramite,
} from "../../../scripts/chartScripts";
import { Get } from "../../../scripts/fetch";
import { MensajeError } from "../../../scripts/mensajePantalla";

const DashboardProspectosStandBy = () => {
    const [standBy, setStandBy] = useState();
    const [standByTipoTramite, setStandByTipoTramite] = useState();
    const [standByMotivo, setStandByMotivo] = useState();

    useEffect(() => {
        const getProspectosStandBy = async () => {
            Get(`charts/standByUltimosDias?dias=30`).then((d) => {
                setStandBy(createFormatableObject(d.obj, "mes"));
            });
        };
        const getProspectosStandByTipoTramite = async () => {
            Get(`charts/standByTipoTramite?dias=30`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setStandByTipoTramite(d.obj);
            });
        };
        const getProspectosStandByPorMotivo = async () => {
            Get(`charts/standByPorMotivo?dias=30`).then((d) => {
                setStandByMotivo(d.obj);
            });
        };
        getProspectosStandBy();
        getProspectosStandByTipoTramite();
        getProspectosStandByPorMotivo();
    }, []);
    const ExcelListadosAntiguedad = (entry) => {
        let url = "standByAnterioresUltimosDias?dias=30";
        let fileName = "prospectos_standby_antiguos";
        if (entry.name === "Último mes") {
            url = "standByCreadosUltimosDias?dias=30";
            fileName = "prospectos_standby_mes";
        }
        exportToCSV(() => fetchListado(url), fileName);
    };
    const ExcelListadoTipoTramite = async (entry) => {
        const { name } = entry || null;
        if (name) {
            const Id = await getIdTipoTramite(name);
            if (Id) {
                const url = `standByTipoTramiteUltimosDias?dias=30&tipoTramite=${Id}`;
                exportToCSV(() => fetchListado(url), `standby_tramite_${name}`);
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Prospectos en Stand By</h1>
            </div>
            <div className='tricota'>
                {standBy && (
                    <ListadoChart
                        data={formatData(standBy.slice(0, 2))}
                        subtitle={`Antiguedad promedio ${standBy[2].count} días`}
                        progressBar={false}
                        onClickFn={ExcelListadosAntiguedad}
                    />
                )}
                {standByTipoTramite && (
                    <PieChartsJS
                        title='Por Tipo'
                        dataValues={standByTipoTramite.map((stt) => stt.count)}
                        labels={standByTipoTramite.map(
                            (stt) => stt.tipoTramite
                        )}
                        onClickFn={ExcelListadoTipoTramite}
                    />
                )}
                {standByMotivo && (
                    <ListadoChart
                        data={formatData(standByMotivo, "Motivo")}
                        onClickFn={(entry) => {
                            console.log(entry);
                        }}
                        progressBar={true}
                        title='Motivo del Hold'
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardProspectosStandBy;
