import { useEffect, useState } from "react";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import PieChartsJS from "../../../componentes/pie-chartjs/pie-chartjs.component";
import {
    createFormatableObject,
    exportToCSV,
    fetchListado,
    formatData,
    getIdTipoTramite,
    getTraduccionByPeriodo,
} from "../../../scripts/chartScripts";
import { Get } from "../../../scripts/fetch";
import { MensajeError } from "../../../scripts/mensajePantalla";

const DashboardProspectosBaja = () => {
    const [bajasUltimosDias, setbajasUltimosDias] = useState();
    const [bajasTipoTramite, setbajasTipoTramite] = useState();
    const [bajasMotivo, setbajasMotivo] = useState();
    useEffect(() => {
        const getBajasUltimosDias = async () => {
            Get(`charts/bajasUltimosDias?dias=30`).then((d) => {
                // console.log(createFormatableObject(d.obj));
                setbajasUltimosDias(createFormatableObject(d.obj, "mes"));
            });
        };
        const getBajaPorTipoTramite = async () => {
            Get(`charts/bajasPorTipoTramite?dias=30`).then((d) => {
                d.obj.sort( (a, b) => b.count - a.count );
                setbajasTipoTramite(d.obj);
            });
        };
        const getBajaPorMotivo = async () => {
            Get(`charts/bajasPorMotivo?dias=30`).then((d) => {
                setbajasMotivo(d.obj);
            });
        };
        getBajasUltimosDias();
        getBajaPorTipoTramite();
        getBajaPorMotivo();
    }, []);
    const ExcelListadosAntiguedad = (entry) => {
        let url = "bajaAnterioresUltimosDias?dias=30";
        let fileName = "prospectos_baja_antiguos";
        if (
            entry.name === getTraduccionByPeriodo("cantidadUltimosDias", "mes")
        ) {
            url = "bajaCreadosUltimosDias?dias=30";
            fileName = "prospectos_baja_mes";
        }
        exportToCSV(() => fetchListado(url), fileName);
    };
    const ExcelListadoTipoTramite = async (entry) => {
        const { name } = entry || null;
        if (name) {
            const Id = await getIdTipoTramite(name);
            if (Id) {
                const url = `bajaTipoTramiteUltimosDias?dias=30&tipoTramite=${Id}`;
                exportToCSV(() => fetchListado(url), `baja_tramite_${name}`);
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Prospectos en Baja</h1>
            </div>
            <div className='tricota'>
                {bajasUltimosDias && (
                    <ListadoChart
                        progressBar={false}
                        data={formatData(bajasUltimosDias)}
                        onClickFn={ExcelListadosAntiguedad}
                    />
                )}
                {bajasTipoTramite && (
                    <PieChartsJS
                        title='Por Tipo'
                        dataValues={bajasTipoTramite.map((bt) => bt.count)}
                        labels={bajasTipoTramite.map((bt) => bt.tipoTramite)}
                        onClickFn={ExcelListadoTipoTramite}
                    />
                )}
                {bajasMotivo && (
                    <ListadoChart
                        data={formatData(bajasMotivo, "Motivo")}
                        onClickFn={(entry) => {
                            console.log(entry);
                        }}
                        progressBar={true}
                        title='Motivo de la Baja'
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardProspectosBaja;
