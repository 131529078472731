import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ObjetivosColumns } from "../../data/tableColumns";
import { Get } from "../../scripts/fetch";
import { MensajeError } from "../../scripts/mensajePantalla";
import TablaReact from "../tables/tablaReact";

const ObjetivosContainer = () => {
    const [objetivos, setobjetivos] = useState();
    useEffect(() => {
        const getObjetivos = async () => {
            const res = await Get(`objetivos`);
            // console.log(res.obj);
            if (res.ok) setobjetivos(res.obj);
            else MensajeError("Ocurrio un error al obtener los objetivos");
        };
        getObjetivos();
    }, []);
    // console.log(objetivos);
    return (
        <Container fluid>
            <h1>Configurar Objetivos Mensuales</h1>
            {objetivos && (
                <TablaReact columnas={ObjetivosColumns} datos={objetivos} />
            )}
        </Container>
    );
};

export default ObjetivosContainer;
