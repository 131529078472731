import { useContext, useEffect, useState } from "react";
import PieChartsJS from "../../componentes/pie-chartjs/pie-chartjs.component";
import { SwiperContext } from "../../provider/swiper/swiper.provider";
import { exportToCSV } from "../../scripts/chartScripts";
import { Get } from "../../scripts/fetch";
import { MensajeError } from "../../scripts/mensajePantalla";

// 122
// Este Dash necesita un id de asesor y de tramite, ver como hacer para pasarselos al componente sin hacer negradas
// Recibo el parametro por useContext como activeID, se considera negrada?
const DashboardDesempenoAsesor = ({ swipe }) => {
    const { activeID: idVendedor } = useContext(SwiperContext);

    const [bajasVendedor, setbajasVendedor] = useState();
    const [cierresVendedorTramite, setcierresVendedorTramite] = useState();

    useEffect(() => {
        const getBajasVendedor = async () => {
            Get(
                `charts/bajasPorVendedorPorTramite?dias=0&vendedor=${idVendedor}`
            ).then((d) => {
                d.obj.data.sort( (a, b) => b.count - a.count );
                setbajasVendedor(d.obj);
            });
        };

        const getCierresVendedorPorTramite = async () => {
            Get(
                `charts/cierrePorVendedorPorTramite?dias=0&vendedor=${idVendedor}`
            ).then((d) => {
                d.obj.data.sort( (a, b) => b.count - a.count );
                setcierresVendedorTramite(d.obj);
            });
        };

        if (idVendedor && idVendedor > 0) {
            getBajasVendedor();
            getCierresVendedorPorTramite();
        } else swipe(10); //Lo hago volver a desempeño de equipo sino llego nada (por si scrollean la ultima pagina)
        //eslint-disable-next-line
    }, [idVendedor]);

    const fetchListado = async (listado) => {
        const data = await Get(`listados/${listado}`);
        if (data.ok) return data.obj;

        return null;
    };

    const ExcelListadosBajasVendedor = async (entry) => {
        const { name } = entry || null;

        if (name) {
            const objTipoTramites = await Get(`tipoTramite`);
            if (objTipoTramites) {
                const { Id } = objTipoTramites.find(
                    (tt) => tt.Descripcion === name
                );
                const url = `desempenoBajaAsesor?dias=30&tipoTramite=${Id}&asesor=${idVendedor}`;
                exportToCSV(
                    () => fetchListado(url),
                    `bajas_${bajasVendedor.Vendedor}_${name}`
                );
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };

    const ExcelListadosCierresVendedorTramite = async (entry) => {
        const { name } = entry || null;

        if (name) {
            const objTipoTramites = await Get(`tipoTramite`);
            if (objTipoTramites) {
                const { Id } = objTipoTramites.find(
                    (tt) => tt.Descripcion === name
                );
                const url = `desempenoCierreAsesor?dias=30&tipoTramite=${Id}&asesor=${idVendedor}`;
                exportToCSV(
                    () => fetchListado(url),
                    `cierres_${bajasVendedor.Vendedor}_${name}`
                );
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };

    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                {bajasVendedor && (
                    <h1>Desempeño de {bajasVendedor.Vendedor}</h1>
                )}
            </div>
            <div className='mitad'>
                {bajasVendedor && (
                    <PieChartsJS
                        labels={bajasVendedor.data.map((p) => p.TipoTramite)}
                        dataValues={bajasVendedor.data.map((p) => p.count)}
                        title='Bajas'
                        onClickFn={ExcelListadosBajasVendedor}
                    />
                )}
                {cierresVendedorTramite && (
                    <PieChartsJS
                        labels={cierresVendedorTramite.data.map(
                            (p) => p.TipoTramite
                        )}
                        dataValues={cierresVendedorTramite.data.map(
                            (p) => p.count
                        )}
                        title='Cierres'
                        onClickFn={ExcelListadosCierresVendedorTramite}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardDesempenoAsesor;
