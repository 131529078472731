import { useEffect, useState } from "react";
import { ListadoChart } from "../../../componentes/listadochart/ListadoChart";
import {
    exportToCSV,
    fetchListado,
    formatData,
    getIdTipoTramite,
} from "../../../scripts/chartScripts";

import { Get } from "../../../scripts/fetch";
import { MensajeError } from "../../../scripts/mensajePantalla";

const diasMesActual = new Date().getDate() - 1; // Para calcular el inicio de mes

const DashboardPrincipalCierres = ({ swipe }) => {
    const [cierresBeneficios, setcierresBeneficios] = useState();
    const [cierresJuridicos, setcierresJuridicos] = useState();
    const beneficios = [
        "Consulta",
        "Jubilación",
        "PUAM",
        "Retiro por invalidez",
        "Pensión por Fallecimiento",
    ];
    useEffect(() => {
        const getCierresTipoTramite = async () => {
            return Get(`charts/cierresPorTipoTramite?dias=${diasMesActual}`).then((d) => {
                const arrBeneficios = d.obj.filter((t) =>
                    beneficios.find((b) => b === t.tipoTramite)
                );
                const arrJuridicos = d.obj.filter(
                    (t) => !beneficios.find((b) => b === t.tipoTramite)
                );
                setcierresBeneficios(arrBeneficios);
                setcierresJuridicos(arrJuridicos);
            });
        };
        getCierresTipoTramite();
        //eslint-disable-next-line
    }, []);
    const totalArray = (arr) => {
        if (arr && arr.length > 0)
            return arr.reduce((acc, cur) => acc + cur.count, 0);
        return 0;
    };
    const ExcelListadosPorTipoTramite = async (entry) => {
        const { name } = entry || null;
        if (name) {
            const Id = await getIdTipoTramite(name);
            if (Id) {
                const url = `cierresPorTipoDeTramite?dias=30&tipoTramite=${Id}`;
                exportToCSV(() => fetchListado(url), `cierres_tramite_${name}`);
            } else
                MensajeError(
                    "Ocurrio un error al buscar los tipos de trámite, intente nuevamente más tarde."
                );
        }
    };
    const totalCierres =
        totalArray(cierresBeneficios) + totalArray(cierresJuridicos);
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Cierres</h1>
            </div>
            <div className='cierre'>
                {cierresBeneficios && (
                    <ListadoChart
                        data={formatData(cierresBeneficios, "tipoTramite")}
                        title='Beneficios'
                        progressBar={false}
                        onClickFn={ExcelListadosPorTipoTramite}
                    />
                )}
                <div>
                    <h4 className='subNumeroPrincipal'>Total General</h4>
                    <h1
                        className='numeroPrincipal clickable'
                        onClick={() => (swipe ? swipe(8) : null)}>
                        {totalCierres}
                    </h1>
                </div>
                {cierresJuridicos && (
                    <ListadoChart
                        data={formatData(cierresJuridicos, "tipoTramite")}
                        title='Asuntos Jurídicos'
                        progressBar={false}
                        onClickFn={ExcelListadosPorTipoTramite}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardPrincipalCierres;
