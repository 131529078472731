import { toast } from "react-toastify";

const msgFaltanDatos = "Ingrese los datos obligatorios para continuar";

const Mensaje = ({ closeToast, toastProps, titulo, mensaje }) => (
    <div>
        <p>{titulo}</p>
        <small>{mensaje}</small>
    </div>
);
export function MensajeExito(titulo, mensaje = " ") {
    return toast.success(<Mensaje titulo={titulo} mensaje={mensaje} />);
}
export function MensajeError(titulo, mensaje = " ") {
    return toast.error(<Mensaje titulo={titulo} mensaje={mensaje} />);
}
export function MensajeFaltanDatos(msg = msgFaltanDatos) {
    const titulo = "Faltan Datos";
    return toast.warn(<Mensaje titulo={titulo} mensaje={msg} />);
}
export function MensajeIngreseNota(mensaje) {
    const titulo = "Ingrese una Nota";
    return toast.error(<Mensaje titulo={titulo} mensaje={mensaje} />);
}
