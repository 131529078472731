import { useEffect, useState } from "react";
import VelocimetroChart from "../../../componentes/gauge-chartjs/gauge-chartjs.component";
import { TIPO_TRAMITE } from "../../../scripts/consts";
import { MensajeError } from "../../../scripts/mensajePantalla";
import { Get } from "../../../scripts/fetch";

const DashboardPrincipalObjetivos = () => {
    const [objetivosMensuales, setobjetivosMensuales] = useState();
    const [cierresPorTipoDeTramite, setcierresPorTipoDeTramite] = useState();
    useEffect(() => {
        const getObjetivosMensuales = async () => {
            const res = await Get(`objetivos`);
            if (res.ok) setobjetivosMensuales(res.obj);
            else
                MensajeError(
                    "Ocurrio un error al buscar los objetivos mensuales"
                );
        };
        const getCierresTipoTramite = async () => {
            const res = await Get(`charts/cierresPorTipoTramite?meses=1`);
            if (res.ok) setcierresPorTipoDeTramite(res.obj);
            else
                MensajeError(
                    "Ocurrio un error al buscar los cierres por tipo de tramite"
                );
        };
        getCierresTipoTramite();
        getObjetivosMensuales();
    }, []);
    const getPorcentaje = (tipoTramite) => {
        const { Objetivo } = objetivosMensuales.find(
            (obj) => obj.TipoTramite === tipoTramite
        );
        const { count = 0 } =
            cierresPorTipoDeTramite.find(
                (cie) => cie.tipoTramite === tipoTramite
            ) || {};
        const ret = (count * 0.5) / Objetivo;
        return ret > 1 ? 1 : ret;
    };
    const getDiferencia = (tipoTramite) => {
        const { Objetivo } = objetivosMensuales.find(
            (obj) => obj.TipoTramite === tipoTramite
        );
        const { count = 0 } =
            cierresPorTipoDeTramite.find(
                (cie) => cie.tipoTramite === tipoTramite
            ) || {};
        return Number.parseInt(count) - Number.parseInt(Objetivo);
    };
    return (
        <div className='chartContainer'>
            <div className='chartTitle'>
                <h1>Objetivos Mensuales</h1>
            </div>
            <div className='gridSeisColumnas'>
                {objetivosMensuales &&
                    cierresPorTipoDeTramite &&
                    Object.entries(TIPO_TRAMITE).map((tt) => (
                        <VelocimetroChart
                            key={tt}
                            porcentaje={getPorcentaje(tt[1])}
                            titulo={tt[1]}
                            valor={getDiferencia(tt[1])}
                        />
                    ))}
            </div>
        </div>
    );
};

export default DashboardPrincipalObjetivos;
