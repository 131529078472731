import { useState, useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Delete, Get } from "../../../scripts/fetch";
import { confirmar } from "../../../scripts/general";
import { MensajeExito, MensajeError } from "../../../scripts/mensajePantalla";
import TablaReact from "../../tables/tablaReact";
import { ObjConfigColumns } from "../../../scripts/dynamicColumns";
import "../../../css/show-config.styles.css";

const Listar = ({ modelo }) => {
    //todo tengo que tomar X variables del modelo y hacerlas columna, si se puede filtrar o no, ni idea, vemos.
    const [objModelo, setobjModelo] = useState([]);
    const objColumnas = useRef();
    const fetchModelo = () => {
        Get(modelo).then((cfg) => {
            // console.log(cfg.obj);
            objColumnas.current = new ObjConfigColumns(cfg.obj[0], modelo);
            // console.log(objColumnas);
            setobjModelo([]); //Sino lo blanqueo devuelta, no engancha los cambios, intente spredearlo, pero ni bola
            setobjModelo(cfg.obj);
        });
    };
    useEffect(() => {
        fetchModelo();
        //eslint-disable-next-line
    }, []); //only one render
    const handleDelete = (e) => {
        confirmar(`Desea borrar?`, (res) => {
            if (res) {
                Delete(`${modelo}/${e}`)
                    .then((ok) => {
                        MensajeExito("Se elimino correctamente");
                        fetchModelo();
                    })
                    .catch((err) => {
                        MensajeError("Error al eliminar", err.message);
                    });
            }
        });
    };
    return (
        <Container fluid>
            <h3>Configuración</h3>
            {objModelo.length === 0 ? null : (
                <TablaReact
                    columnas={objColumnas.current.Columnas(handleDelete)}
                    datos={objModelo}
                />
            )}
            <Link to={{ pathname: `/Config/${modelo}/0`, id: 0 }}>
                <Button>Agregar</Button>
            </Link>
        </Container>
    );
};

export default Listar;
