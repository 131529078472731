import { useState } from "react";
import { Form } from "react-bootstrap";

const EncuadreProfesionales = ({ profesionales, resultado, onClose }) => {
    const [profesional, setProfesional] = useState(
        profesionales ? profesionales[0] : {}
    );
    return (
        <div className='react-confirm-alert'>
            <div className='react-confirm-alert-body'>
                <h1>Andrea Falcone App.</h1>
                <p>Seleccione profesional para el encuadre</p>
                {profesionales
                    ? profesionales.map((pro, ix) => (
                          <div key={ix}>
                              <Form.Check
                                  defaultChecked={ix === 0}
                                  data-testid='checkProfesionales'
                                  type='radio'
                                  name='profesionalRadios'
                                  value={pro.id}
                                  label={pro.nombre}
                                  onClick={() => setProfesional(pro)}
                              />
                          </div>
                      ))
                    : null}
                <div className='react-confirm-alert-button-group'>
                    <button
                        name='Asignar'
                        onClick={() => {
                            resultado(profesional);
                            onClose();
                        }}>
                        Asignar
                    </button>
                    <button name='Cancelar' onClick={() => onClose()}>
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EncuadreProfesionales;
