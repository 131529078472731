import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ColumnLabelStyled = styled(Form.Label)`
    border-collapse: separate;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: Alegreya;
    font-size: 12px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    text-align: left;
    text-indent: 0px;
    text-size-adjust: 100%;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
