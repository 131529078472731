import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Cell,
    ResponsiveContainer,
} from "recharts";

const BarChartComponent = (props) => {
    const {
        data,
        colores,
        dataKeyName,
        dataKeyValue,
        dataKeyLabel,
        aspect = 7,
    } = props;
    return (
        <ResponsiveContainer width='100%' aspect={aspect}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={dataKeyName} />
                <YAxis />
                <Tooltip />
                <Bar dataKey={dataKeyValue} name={dataKeyLabel}>
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colores[index % colores.length]}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartComponent;
