import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import GoogleFontLoader from "react-google-font-loader";

import "bootstrap/dist/css/bootstrap.min.css";

// import './css/fonts.css';

import "./index.css";

import "./css/main.css";
// import './css/menu.css';
// import './css/style.min.css';
import "./css/estilo.css";
// import './css/tooltipster-light.css';
// import './css/tooltipster.css';
// import './css/bootstrap-datetimepicker.min.css';
import "react-toastify/dist/ReactToastify.css";
import "./css/mensajes.css";
import UserContextProvider from "./provider/user/user.provider";

ReactDOM.render(
    <React.StrictMode>
        <UserContextProvider>
            <GoogleFontLoader
                fonts={[
                    {
                        font: "Raleway",
                    },
                ]}
            />
            <App />
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
