let host = `${window.location.protocol}//${
    window.location.host.indexOf("localhost") >= 0
        ? "localhost:3000"
        : window.location.host
}`;
// let host = `http://localhost:3000`;

export async function Get(url) {
    return Promise.resolve(
        fetch(`${host}/${url}`)
            .then((res) => {
                return res.json();
            })
            .catch((e) => {
                console.log(e);
                throw e;
            })
    );
}
export async function Post(url, stringifiedPostData) {
    return Promise.resolve(
        fetch(`${host}/${url}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: stringifiedPostData,
        })
            .then((res) => res.json())
            .catch((e) => {
                throw e;
            })
    );
}
export async function Put(url, stringifiedPostData) {
    return Promise.resolve(
        fetch(`${host}/${url}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: stringifiedPostData,
        })
            .then((res) => res.json())
            .catch((e) => {
                throw e;
            })
    );
}
export async function Delete(url, stringifiedPostData) {
    return Promise.resolve(
        fetch(`${host}/${url}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: stringifiedPostData,
        })
            .then((res) => res.json())
            .catch((e) => {
                throw e;
            })
    );
}
export async function PostFile(url, file) {
    const formdata = new FormData();
    formdata.append("archivo", file, file.name);
    const requestOptions = {
        method: "PUT",
        body: formdata,
        redirect: "follow",
    };

    fetch(`${host}/${url}`, requestOptions)
        .then((res) => res.json())
        .catch((e) => {
            throw e;
        });
}
