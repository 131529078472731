import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Col, Row, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Get, Post, Put } from "../../../scripts/fetch";
import { getValue } from "../../../scripts/general";
import { TEXT_BTN_CONFIRM } from "../../../scripts/consts";
import { MensajeError, MensajeExito } from "../../../scripts/mensajePantalla";

// const Editar = ({ location: { id }, modelo }) => {
const Editar = ({ id, modelo }) => {
    const [objConfig, setobjConfig] = useState(null);
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        if (id) {
            if (id && id !== "0") {
                // console.log('Entro en != 0', id);
                Get(`${modelo}/${id}`).then((cfg) => {
                    delete cfg.obj["_id"];
                    delete cfg.obj["__v"];
                    setobjConfig(cfg.obj);
                });
            } else if (Number(id) === 0) {
                // console.log('Entro en === 0');
                Get(`${modelo}/get/Model`).then((cfg) => {
                    const obj = cfg.obj.reduce(
                        (o, key) => ({ ...o, [key]: "" }),
                        {}
                    );
                    delete obj["_id"];
                    delete obj["__v"];
                    setobjConfig(obj);
                });
            }
        }
        //Si no tiene id, no tengo modelo, tengo que armar una forma de pasar el modelo desde el BE al FE.
        //eslint-disable-next-line
    }, [id]);
    const handleTextEvent = (e) => {
        setobjConfig({ ...objConfig, [e.target.name]: e.target.value });
    };
    const title =
        id && id === "0" ? TEXT_BTN_CONFIRM.AGREGAR : TEXT_BTN_CONFIRM.EDITAR;

    const handleSubmit = (event) => {
        event.preventDefault();
        const { currentTarget: form } = event;

        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            setValidated(false);
            // const nombreObj = `Obj${modelo}`;
            const obj = JSON.stringify({
                [modelo[0].toLowerCase() + modelo.substring(1)]: {
                    ...objConfig,
                },
            });
            // console.log(obj);
            if (title === TEXT_BTN_CONFIRM.AGREGAR) {
                Post(modelo, obj)
                    .then((res) => {
                        if (res.ok) MensajeExito("Se agrego correctamente");
                        else MensajeError(res.err.msg);
                    })
                    .catch((err) => {
                        MensajeError("Error al agregar", err.message);
                    });
            } else if (title === TEXT_BTN_CONFIRM.EDITAR) {
                Put(`${modelo}/${id}`, obj)
                    .then((res) => {
                        MensajeExito("Se actualizo correctamente");
                    })
                    .catch((err) => {
                        MensajeError("Error al actualizar", err.message);
                    });
            }
        }
    };
    const renderForm = () => {
        return Object.entries(objConfig).map((entry, ix) => (
            <Row key={ix}>
                <Col xs={3}>
                    <Form.Label>{entry[0]}</Form.Label>
                </Col>
                <Col xs={3}>
                    <Form.Control
                        required
                        name={entry[0]}
                        onChange={handleTextEvent}
                        value={getValue(objConfig[entry[0]])}
                        type='text'
                        placeholder={`Ingrese ${entry[0]}...`}
                    />
                </Col>
            </Row>
        ));
    };
    return (
        <Container fluid>
            {typeof id === "undefined" || id === null ? (
                <Redirect to={`/Config/${modelo}/`} />
            ) : null}
            <h3>{title}</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {objConfig ? renderForm() : null}
                <Button type='submit'>{title}</Button>
                <Link to={`/Config/${modelo}`}>
                    <Button>Volver</Button>
                </Link>
            </Form>
        </Container>
    );
};

export default Editar;
