import { useState } from "react";
import { Form } from "react-bootstrap";

const MotivosStandBy = ({ motivos, resultado, onClose }) => {
    const [motivo, setMotivo] = useState(
        motivos ? motivos[0] : {}
    );
    return (
        <div className='react-confirm-alert'>
            <div className='react-confirm-alert-body'>
                <h1>Andrea Falcone App.</h1>
                <p>MotivoStandBy</p>
                {motivos
                    ? motivos.map((motivo, ix) => (
                          <div key={ix}>
                              <Form.Check
                                  defaultChecked={ix === 0}
                                  data-testid='checkBaja'
                                  type='radio'
                                  name='bjaRadios'
                                  value={motivo.id}
                                  label={motivo.descripcion}
                                  onClick={() => setMotivo(motivo)}
                              />
                          </div>
                      ))
                    : null}
                <div className='react-confirm-alert-button-group'>
                    <button
                        name='Asignar'
                        onClick={() => {
                            resultado(motivo);
                            onClose();
                        }}>
                        Asignar
                    </button>
                    <button name='Cancelar' onClick={() => onClose()}>
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MotivosStandBy;
