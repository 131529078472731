import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Get, Put } from "../../scripts/fetch";
import { MensajeError, MensajeExito } from "../../scripts/mensajePantalla";

const EditObjetivo = ({ location: { id } }) => {
    const [objetivo, setobjetivo] = useState();
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        const getObjetivo = async (objetivo) => {
            const res = await Get(`objetivos/${objetivo}`);
            if (res.ok) setobjetivo(res.obj);
            else MensajeError("Error al buscar el objetivo");
        };
        getObjetivo(id);
    }, [id]);
    const handleTextEvent = (evt) => {
        setobjetivo({ ...objetivo, [evt.target.name]: evt.target.value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const { currentTarget: form } = event;

        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            setValidated(false);
            const obj = JSON.stringify({ Objetivo: objetivoMensual });
            Put(`objetivos/${id}`, obj).then((res) => {
                if (res.ok)
                    MensajeExito("Se actualizo el objetivo correctamente");
                else MensajeError("Ocurrio un error al actualizar el objetivo");
            });
        }
    };
    const { TipoTramite, Objetivo: objetivoMensual } = objetivo || {};
    return (
        <Container>
            {typeof id === "undefined" ? (
                <Redirect to='/Config/Objetivos' />
            ) : null}
            <h1>Editar Objetivo</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col xs={3}>
                        <Form.Label>Tipo de Trámite</Form.Label>
                    </Col>
                    <Col xs={3}>
                        <Form.Label>{TipoTramite}</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Form.Label>Objetivo Mensual</Form.Label>
                    </Col>
                    <Col xs={3}>
                        <Form.Control
                            required
                            name='Objetivo'
                            onChange={handleTextEvent}
                            value={objetivoMensual}
                            type='number'
                            placeholder={`Ingrese el valor del objetivo mensual...`}
                        />
                    </Col>
                </Row>
                <Button type='submit'>Actualizar</Button>
                <Link to={`/Config/Objetivos`}>
                    <Button>Volver</Button>
                </Link>
            </Form>
        </Container>
    );
};

export default EditObjetivo;
